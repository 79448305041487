/* eslint-disable no-unused-vars */
import { BenefitsCharges } from "models/Invoice/BenefitsCharges";
import { InvoiceModifications } from "models/Invoice/InvoiceModifications";
import { Field } from "sparkson";
import { Marker } from "../Marker";
import { Amounts } from "./Amounts";
import { Company } from "./Company";
import { Dates } from "./Dates";
import { Salary } from "./Salary";
import { Sparkbit } from "./Sparkbit";

@Marker
export class InvoiceData {
    constructor(
        @Field("status") public status: "missing" | "processing" | "issued",
        @Field("number") public invoiceNumber: string,
        @Field("dates") public dates: Dates,
        @Field("company_data") public companyData: Company,
        @Field("sparkbit_data") public sparkbitData: Sparkbit,
        @Field("base_salary") public baseSalary: Salary,
        @Field("amounts") public amounts: Amounts,
        @Field("benefits") public benefitCharges: BenefitsCharges,
        @Field("invoice_modifications") public invoiceModifications: InvoiceModifications
    ) { }
}
