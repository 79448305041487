import { action, makeObservable, observable } from "mobx";

import { Invoice } from "models/Invoice/Invoice";
import moment from "moment";
import InvoiceListStore from "stores/InvoiceListStore";
import invoice from "../api/controllers/Invoice";
import AppStore from "./AppStore";

class Store {
    @observable public invoice?: Invoice;
    @observable public activeMonth?: Date;

    constructor() {
        makeObservable(this);
    }

    @action public resetStore = () => {
        this.invoice = undefined;
        this.activeMonth = undefined;
    };

    @action public setInvoice = async (newInvoice: Invoice) => {
        this.invoice = newInvoice;
    };

    @action public getInvoice = async () => {
        if (!this.activeMonth) {
            const invoiceData = await invoice.getData();

            if (invoiceData) {
                this.setActiveMonth(moment(invoiceData.month).toDate());
                this.invoice = invoiceData;
            }
        } else {
            this.getInvoiceForActiveMonth();
        }
    };

    @action public getInvoiceForActiveMonth = async () => {
        const params = this.parseMonthAndYear();
        this.invoice = undefined;
        this.invoice = await invoice.getData(params);
    };

    @action public downloadInvoiceForActiveMonth = async () => {
        const month = moment(this.activeMonth).format("YYYY-MM");

        AppStore.showFader();
        const { body, headers } = await invoice.downloadInvoice(month);
        AppStore.hideFader();

        const fileName = InvoiceListStore.getFileName(headers);

        InvoiceListStore.downloadFile(body, fileName, "application/pdf");
    };

    @action public generateInvoiceForActiveMonth = async () => {
        const params = this.parseMonthAndYear();

        AppStore.showFader();

        const invoiceData = await invoice.generateInvoice(params);

        AppStore.hideFader();

        this.invoice = invoiceData;
    };

    @action public uploadInvoiceForActiveMonth = async (file: File) => {
        const params = this.parseMonthAndYear();

        AppStore.showFader();

        const invoiceData = await invoice.uploadInvoice(params, file);

        AppStore.hideFader();

        this.invoice = invoiceData;
    };

    @action public setActiveMonth = (month: Date) => {
        this.activeMonth = month;
    };

    private parseMonthAndYear = () => {
        const month = moment(this.activeMonth);

        return `${month.format("YYYY")}-${String(month.format("M")).padStart(2, "0")}`;
    };
}

const InvoiceStore = new Store();

export default InvoiceStore;
