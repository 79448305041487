import { observer } from "mobx-react-lite";
import React from "react";

import AppStore from "stores/AppStore";

import "./InvoiceListTableHeader.scss";

const InvoiceListTableHeader = () => {
    const {
        i18n: {
            employees: { name },
            invoiceStatuses: { invoiceStatus, amountsPLN }
        }
    } = AppStore;

    return (
        <thead className="c-invoice-list-table-header">
            <tr className="c-invoice-list-table-header__tr">
                <th className="c-invoice-list-table-header__th c-invoice-list-table-header__name u-align-left">{name}</th>
                <th className="c-invoice-list-table-header__th u-align-left">{invoiceStatus}</th>
                <th className="c-invoice-list-table-header__th" />
                <th className="c-invoice-list-table-header__th" />
                <th className="c-invoice-list-table-header__th" />
                <th className="c-invoice-list-table-header__th c-invoice-list-table-header__amount u-align-right">{amountsPLN}</th>
            </tr>
        </thead>
    );
};

export default observer(InvoiceListTableHeader);
