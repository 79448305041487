import { Field, Form, Formik, FormikProps } from "formik";
import { observer } from "mobx-react-lite";
import React from "react";

import CheckboxInput from "components/commons/Formik/CheckboxInput/CheckboxInput";
import { Employee } from "models/Employee/Employee";
import AppStore from "stores/AppStore";
import EmployeeDataStore from "stores/EmployeeManagementStore";

import "./EmploymentIsInvocingForm.scss";

const DataForm: React.FC<Employee & FormikProps<Employee>> = ({ submitForm, isValid }) => {
    const handleChange = () => {
        if (isValid) {
            submitForm();
        }
    };

    const {
        i18n: {
            employees: {
                isInvoicing
            }
        }
    } = AppStore;

    return (
        <Form>
            <Field
                name="isInvoicing"
                label={isInvoicing}
                component={CheckboxInput}
                onChange={handleChange}
            />
        </Form>
    );
};

const EmploymentIsInvocingForm = () => {
    const { employee: { isInvoicing } } = EmployeeDataStore;

    const mapValues = (values: Employee) => ({
        is_invoicing: values.isInvoicing
    });

    const handleSubmit = async (values) => {
        await EmployeeDataStore.putEmployeeData(mapValues(values) as any);
        EmployeeDataStore.getEmployeeData();
    };

    return (

        <div className="c-employment-is-invoicing-form">
            <Formik
                initialValues={{ isInvoicing }}
                onSubmit={handleSubmit}
                component={DataForm}
            />
        </div>

    );
};

export default observer(EmploymentIsInvocingForm);
