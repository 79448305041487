/* eslint-disable no-unused-vars */
import { Field } from "sparkson";
import { Marker } from "../Marker";
import { Address } from "./Address";

@Marker
export class Sparkbit {
    constructor(
        @Field("company_name") public companyName: string,
        @Field("nip") public nip: string,
        @Field("address") public address: Address
    ) {}
}
