import { observer } from "mobx-react-lite";
import React from "react";
import { createPortal } from "react-dom";

import AppStore from "stores/AppStore";
import "./Fader.scss";

const faderRoot = document.getElementById("fader");

const Fader: React.FC = () => {
    const { isFaderVisible } = AppStore;

    if (!isFaderVisible) {
        return null;
    }

    return createPortal(
        <div className="c-fader">
            <div className="c-fader__lds-ellipsis">
                <div />
                <div />
                <div />
                <div />
            </div>{" "}
        </div>,
        faderRoot
    );
};

export default observer(Fader);
