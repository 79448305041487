import AppStore from "stores/AppStore";

export const notEmpty = (message: string) => (value: any) => {
    if (!value) {
        return message;
    }

    return undefined;
};

export const isNipValid = (nip: string) => {
    const invalidMessage = AppStore.i18n.commons.invalidNipNumber;
    const truncatedNip = nip.replace("-", "");

    if (truncatedNip.length === 0) {
        return AppStore.i18n.employees.fieldCantBeEmpty;
    }

    if (truncatedNip.length !== 10) {
        return invalidMessage;
    }

    const weights = [6, 5, 7, 2, 3, 4, 5, 6, 7];
    const controlNumber = parseInt(truncatedNip[9]);
    let sum = 0;

    for (let i = 0; i < weights.length; i++) {
        sum += weights[i] * parseInt(truncatedNip[i]);
    }

    if (sum % 11 !== controlNumber) {
        return invalidMessage;
    }

    return undefined;
};

export const isBankAccountNumberValid = (bankAccountNumber: string) => {
    const invalidMessage = AppStore.i18n.commons.invalidBankAccountNumber;
    let truncatedBankAccountNumber = bankAccountNumber.replace(/ /g, "");

    if (truncatedBankAccountNumber.length === 0) {
        return AppStore.i18n.employees.fieldCantBeEmpty;
    }

    if (truncatedBankAccountNumber.length !== 26) {
        return invalidMessage;
    }

    const weights = [1, 10, 3, 30, 9, 90, 27, 76, 81, 34, 49, 5, 50, 15, 53, 45, 62, 38, 89, 17, 73, 51, 25, 56, 75, 71, 31, 19, 93, 57];

    truncatedBankAccountNumber += "2521";

    truncatedBankAccountNumber = truncatedBankAccountNumber.substr(2) + truncatedBankAccountNumber.substr(0, 2);
    let sum = 0;

    for (let i = 0; i < truncatedBankAccountNumber.length; i++) {
        sum += parseInt(truncatedBankAccountNumber[29 - i]) * weights[i];
    }

    if (sum % 97 !== 1) {
        return invalidMessage;
    }

    return undefined;
};

export const isZipCodeValid = (zipCode: string) => {
    if (zipCode.length === 0) {
        return AppStore.i18n.employees.fieldCantBeEmpty;
    }

    if (!zipCode.match(/^\d\d-\d\d\d$/)) {
        return AppStore.i18n.commons.invalidZipCode;
    }

    return undefined;
};
