import { observer } from "mobx-react-lite";
import React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";

import Button from "components/commons/Button/Button";
import AppStore from "stores/AppStore";

import "./EmployeeManagementHeader.scss";

const EmployeeManagementHeader: React.FC<RouteComponentProps> = ({ history }) => {
    const {
        i18n: {
            employees: { backToList }
        }
    } = AppStore;

    return (
        <div className="c-employee-management-header">
            <Button
                text={backToList}
                className="c-employee-management-header__button"
                onClick={() => {
                    history.push("/employee");
                }}
            />
        </div>
    );
};

export default withRouter(observer(EmployeeManagementHeader));
