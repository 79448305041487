/* eslint-disable no-unused-vars */
import { Field } from "sparkson";
import { Marker } from "../Marker";
import { Address } from "./Address";

@Marker
export class Company {
    constructor(
        @Field("company_name", true, null, "") public companyName: string,
        @Field("nip", true, null, "") public nip: string,
        @Field("service_name", true, null, "") public serviceName: string,
        @Field("bank_account_number", true, null, "") public bankAccountNumber: string,
        @Field("vat_exemption", true, null, "") public vatExemption: boolean,
        @Field("address", true, null, "") public address: Address
    ) { }
}
