import employee from "api/controllers/Employee";
import invoice from "api/controllers/Invoice";
import download from "downloadjs";
import { action, computed, makeObservable, observable } from "mobx";
import type { InvoiceStatus as InvoiceStatusString } from "models/Invoice/InvoiceStatus";
import { InvoiceStatus } from "models/InvoiceStatuses/InvoiceStatus";
import { SummaryAmounts } from "models/InvoiceStatuses/SummaryAmounts";
import moment from "moment";
import AppStore from "stores/AppStore";

class Store {
    @observable public invoiceStatuses?: InvoiceStatus[];
    @observable public total?: SummaryAmounts;
    @observable public issued?: SummaryAmounts;
    @observable public missing?: SummaryAmounts;
    @observable public searchString?: string;
    @observable public source: Set<string> = new Set(["sparkbill", "sparkbill-auto", "upload"]);
    @observable public missingSource: Set<string> = new Set(["missing", "missing-sparkbill-auto"]);
    @observable public invoiceStatus?: InvoiceStatusString;
    @observable public activeMonth?: Date;

    constructor() {
        makeObservable(this);
    }

    @action public resetStore = () => {
        this.invoiceStatuses = undefined;
        this.total = undefined;
        this.issued = undefined;
        this.missing = undefined;
    };

    @action public setActiveMonth = (month: Date) => {
        this.activeMonth = month;
    };

    @action public resetFilters = () => {
        this.searchString = undefined;
        this.invoiceStatus = undefined;
        this.source.add("sparkbill").add("sparkbill-auto").add("upload");
        this.missingSource.add("missing").add("missing-sparkbill-auto");
    };

    @computed get invoiceStatusesFiltered() {
        if (!this.invoiceStatuses) {
            return undefined;
        }

        let invoiceStatuses = [...this.invoiceStatuses];

        if (this.searchString) {
            invoiceStatuses = invoiceStatuses.filter(({ employeeName }) => employeeName.toLowerCase().includes(this.searchString));
        }

        if (this.invoiceStatus) {
            invoiceStatuses = invoiceStatuses.filter(({ invoiceStatus }) => invoiceStatus === this.invoiceStatus);
        }

        if (this.invoiceStatus === "issued") {
            invoiceStatuses = invoiceStatuses.filter(({ invoiceSource }) => this.source.has(invoiceSource));
        }

        if (this.invoiceStatus === "missing") {
            invoiceStatuses = invoiceStatuses.filter(({ employeeAutoGenerationSetting }) => {
                if (employeeAutoGenerationSetting) {
                    return this.missingSource.has("missing-sparkbill-auto");
                }
                return this.missingSource.has("missing");
            });
        }

        return invoiceStatuses;
    }

    @action public addMissingSource = (source) => {
        this.missingSource.add(source);
    };

    @action public deleteMissingSource = (source) => {
        this.missingSource.delete(source);
    };

    @action public addSource = (source) => {
        this.source.add(source);
    };

    @action public deleteSource = (source) => {
        this.source.delete(source);
    };

    @action public getInvoices = async () => {
        let month;

        if (this.activeMonth) {
            month = moment(this.activeMonth).format("YYYY-MM");
        }

        const data = await invoice.getInvoices(month);

        this.setActiveMonth(new Date(data.month));
        this.setInvoiceStatuses(data.invoiceStatuses);
        this.setSummary(data.summary);
    };

    @action public setInvoiceStatuses = (invoiceStatuses) => {
        this.invoiceStatuses = invoiceStatuses;
    };

    @action public setSummary = (summary) => {
        this.total = summary.total;
        this.issued = summary.issued;
        this.missing = summary.missing;
    };

    @action public setSearchString = async (searchString: string) => {
        this.searchString = searchString;
    };

    @action public setInvoiceStatus = async (invoiceStatus: InvoiceStatusString) => {
        this.invoiceStatus = invoiceStatus;
    };

    @action public downloadInvoice = async (employeeId: string) => {
        const month = moment(this.activeMonth).format("YYYY-MM");
        AppStore.showFader();
        const { body, headers } = await employee.downloadInvoice(employeeId, month);
        AppStore.hideFader();
        const fileName = this.getFileName(headers);
        this.downloadFile(body, fileName, "application/pdf");
    };

    @action public downloadAllInvoices = async () => {
        const month = moment(this.activeMonth).format("YYYY-MM");
        AppStore.showFader();
        const { body, headers } = await invoice.downloadAllInvoices(month);
        AppStore.hideFader();
        const fileName = this.getFileName(headers);
        this.downloadFile(body, fileName, "application/zip");
    };

    @action public downloadElixir = async () => {
        const month = moment(this.activeMonth).format("YYYY-MM");
        AppStore.showFader();
        const { body, headers } = await invoice.downloadElixir(month);
        AppStore.hideFader();
        const fileName = this.getFileName(headers);
        this.downloadFile(body, fileName, "text/csv");
    };

    public downloadFile = async (body: ReadableStream<Uint8Array>, fileName: string, fileType: string) => {
        const reader = body.getReader();
        let responseData = new Uint8Array();

        while (true) {
            const data = await reader.read();

            if (data.done) {
                break;
            } else {
                responseData = this.concatUint8Arrays(responseData, data.value);
            }
        }

        download(responseData, fileName, fileType);
    };

    public getFileName = (headers) => {
        const value = headers.get("content-disposition");

        return value.split("\"")[1];
    };

    private concatUint8Arrays = (firstArray, secondArray) => {
        const newArray = new Uint8Array(firstArray.length + secondArray.length);

        newArray.set(firstArray, 0);
        newArray.set(secondArray, firstArray.length);

        return newArray;
    };
}

const InvoiceListStore = new Store();

export default InvoiceListStore;
