import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";

import Loader from "components/commons/Loader/Loader";
import EmployeeDataStore from "stores/EmployeeManagementStore";

import CompanyDataForm from "./components/CompanyDataForm/CompanyDataForm";
import EmploymentStartDateForm from "./components/EmploymentStartDateForm/EmploymentStartDateForm";
import EmploymentIsInvocingForm from "./components/EmploymentIsInvocingForm/EmploymentIsInvocingForm";
import "./CompanyDataManagement.scss";

const CompanyDataManagement = () => {
    const { employee } = EmployeeDataStore;

    useEffect(() => {
        EmployeeDataStore.getEmployeeData();
    }, []);

    if (!employee) {
        return <Loader />;
    }

    const { name, isInvoicing } = employee;

    const renderUser = () => (
        <div className="c-employee-company-data__user">
            <div className="c-employee-company-data__user-icon" />
            <h3 className="c-employee-company-data__user-name">{name}</h3>
        </div>
    );

    return (
        <>
            <div className="c-employee-company-data">
                {renderUser()}
                <EmploymentStartDateForm />
                <EmploymentIsInvocingForm />
                {isInvoicing && <CompanyDataForm />}
            </div>
        </>
    );
};

export default observer(CompanyDataManagement);
