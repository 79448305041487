/* eslint-disable no-unused-vars */
import { Amounts } from "models/Invoice/Amounts";
import { Field } from "sparkson";
import { Marker } from "../Marker";

@Marker
export class InvoiceStatus {
    constructor(
        @Field("invoice_status") public invoiceStatus: "missing" | "processing" | "issued",
        @Field("employee_id") public employeeId: string,
        @Field("employee_name") public employeeName: string,
        @Field("employee_auto_generation_setting") public employeeAutoGenerationSetting: boolean,
        @Field("amounts", true) public amounts?: Amounts,
        @Field("predicted_amounts", true) public predictedAmounts?: Amounts,
        @Field("invoice_source", true) public invoiceSource?: string
    ) {}
}
