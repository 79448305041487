import { observer } from "mobx-react-lite";
import React from "react";

import "./BenefitsCharges.scss";

import Cell from "components/commons/Cell/Cell";
import Divider from "components/commons/Divider/Divider";
import Field from "components/commons/Field/Field";
import Row from "components/commons/Row/Row";
import { groupBy } from "helpers/functions";
import { Charge } from "models/Invoice/Charge";
import AppStore from "stores/AppStore";
import InvoiceStore from "stores/InvoiceStore";

const BenefitsCharges = () => {
    const {
        invoice: {
            invoiceData: { benefitCharges }
        }
    } = InvoiceStore;

    const {
        i18n: { invoiceDetails }
    } = AppStore;

    const { benefitsCharges: benefitsChargesTranslate, benefit, package: packageTranslate, charge: chargeTranslate, totalCharge: totalChargeTranslate } = invoiceDetails;

    const { totalCharge, benefits } = benefitCharges;

    if (!benefits.length) {
        return null;
    }

    const renderRows = (charges: Charge[], key: string) =>
        charges.map((charge, index) => (
            <Row key={charge.packageName}>
                <Cell big bold alignToLeft>
                    {!index && key}
                </Cell>
                <Cell big>{charge.packageName}</Cell>
                <Cell big>
                    <Field value={charge.charge.toString()} disabled />
                </Cell>
            </Row>
        ));

    const renderCharges = () => {
        const elements = [];

        const groupedBenefits = groupBy(benefits, "family");

        Object.keys(groupedBenefits).map((key) => {
            const charges = groupedBenefits[key];

            if (typeof charges === "object") {
                elements.push(renderRows(charges, key));
                elements.push(<Divider key={key} />);
            }
            return true;
        });

        return elements;
    };

    return (
        <div className="c-benefits-charges">
            <h4 className="c-benefits-charges__h4">{benefitsChargesTranslate}</h4>

            <Row>
                <Cell alignToLeft>{benefit}</Cell>
                <Cell>{packageTranslate}</Cell>
                <Cell>{chargeTranslate}</Cell>
            </Row>

            <Divider />

            {renderCharges()}

            <Row>
                <Cell />
                <Cell />
                <Cell>
                    <Field label={totalChargeTranslate} status="negative" value={`${totalCharge.toString()}`} />
                </Cell>
            </Row>
        </div>
    );
};

export default observer(BenefitsCharges);
