import { observer } from "mobx-react-lite";
import React from "react";

import "./TotalAmount.scss";

import Cell from "components/commons/Cell/Cell";
import Divider from "components/commons/Divider/Divider";
import Field from "components/commons/Field/Field";
import HideSensitiveData from "components/commons/HideSensitiveData/HideSensitiveData";
import Row from "components/commons/Row/Row";
import AppStore from "stores/AppStore";
import InvoiceStore from "stores/InvoiceStore";

const TotalAmount = () => {
    const {
        invoice: {
            invoiceData: {
                amounts: { gross, net, vat }
            }
        }
    } = InvoiceStore;

    const {
        i18n: {
            invoiceDetails: { amountNet, vatAmount, amountGross, total }
        }
    } = AppStore;

    return (
        <div className="c-total-amount">
            <Divider color="blue" />

            <h3 className="c-total-amount__h3">{total}</h3>

            <Row>
                <Cell />
                <Cell />
                <Cell>
                    <Field label={amountNet} value={<HideSensitiveData text={net.toString()} />} />
                </Cell>
            </Row>
            <Row>
                <Cell />
                <Cell>
                    <Field label={vatAmount} value={<HideSensitiveData text={vat.toString()} />} disabled />
                </Cell>
                <Cell>
                    <Field className="c-total-amount__amount-gross" label={amountGross} value={<HideSensitiveData text={gross.toString()} />} />
                </Cell>
            </Row>
        </div>
    );
};

export default observer(TotalAmount);
