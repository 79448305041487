import React from "react";

import Page from "components/commons/Page/Page";
import AppStore from "stores/AppStore";
import InvoiceStore from "stores/InvoiceStore";

import Row from "components/commons/Row/Row";
import { Sparkbit } from "models/Invoice/Sparkbit";
import CompanyDetails from "../CompanyDetails/CompanyDetails";
import "./Companies.scss";

const Companies = () => {
    const {
        invoice: {
            invoiceData: { companyData, sparkbitData }
        }
    } = InvoiceStore;

    const {
        i18n: {
            invoicePreview: { company, client }
        }
    } = AppStore;

    return (
        <div className="c-companies">
            <Row>
                <Page>
                    <h3 className="c-companies__h3">{company}:</h3>
                    <CompanyDetails company={companyData} />
                </Page>
                <Page>
                    <h3 className="c-companies__h3">{client}:</h3>
                    <CompanyDetails company={sparkbitData as Sparkbit} />
                </Page>
            </Row>
        </div>
    );
};

export default Companies;
