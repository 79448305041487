import { faCalendar, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import { ErrorMessage, FieldProps } from "formik";
import React, { forwardRef } from "react";
import DatePicker from "react-datepicker";

import { getFormattedDate } from "helpers/functions";
import { Props } from "./DatePickerInputInterface";

import "react-datepicker/dist/react-datepicker.css";
import "./DatePickerInput.scss";

const DatePickerComponent: React.FC<FieldProps & Props> = ({ field, form: { setFieldValue }, label, minDate, placeholder, onChange, className: classNameProps, showYearDropdown = false }: FieldProps & Props) => {
    const { name, value } = field;

    const handleChange = (date: Date) => {
        setFieldValue(name, getFormattedDate(date));

        if (onChange) {
            onChange();
        }
    };

    const handleRemove = (event: React.MouseEvent) => {
        event.stopPropagation();
        setFieldValue(name, "");
    };

    const renderIcon = () => {
        if (value) {
            return (
                <div className="c-date-picker__icon" onClick={handleRemove}>
                    <FontAwesomeIcon icon={faTimes} />
                </div>
            );
        }

        return (
            <div className="c-date-picker__icon">
                <FontAwesomeIcon icon={faCalendar} />
            </div>
        );
    };

    const DatePickerInput = forwardRef(({ onClick }: any) => (
        <div className="c-date-picker__input-wrapper" onClick={onClick}>
            <input className="c-date-picker__input" type="text" autoComplete="off" readOnly placeholder={placeholder} {...field} />
            <div className="c-date-picker__icon-wrapper">{renderIcon()}</div>
        </div>
    ));

    const getSelectedDate = () => {
        if (value) {
            return new Date(value);
        }

        if (minDate) {
            return new Date(minDate);
        }

        return new Date();
    };

    const selectedDate = getSelectedDate();

    const className = classNames("c-date-picker", {
        [classNameProps]: classNameProps
    });

    return (
        <div className={className}>
            <label className="c-date-picker__label" htmlFor={name}>
                {label}
            </label>

            <div className="c-date-picker__date-picker-wrapper">
                <DatePicker
                    onBl
                    selected={selectedDate}
                    showYearDropdown={showYearDropdown}
                    dateFormat="yyyy-MM-dd"
                    showPopperArrow={false}
                    onChange={handleChange}
                    minDate={minDate ? new Date(minDate) : null}
                    customInput={<DatePickerInput />}
                    popperModifiers={{
                        preventOverflow: {
                            boundariesElement: "viewport",
                            enabled: true,
                            escapeWithReference: false
                        }
                    }}
                />
            </div>

            <div className="c-date-picker__error">
                <ErrorMessage name={name} />
            </div>
        </div>
    );
};

export default DatePickerComponent;
