/* eslint-disable no-unused-vars */
import { ArrayField, Field } from "sparkson";
import { Marker } from "../Marker";
import { Employee } from "./Employee";

@Marker
export class Employees {
    constructor(
        @ArrayField("employees", Employee) public employees: Employee[],
        @Field("employee_count") public employeeCount: number
    ) {}
}
