import { ErrorMessage, FieldProps } from "formik";
import React from "react";

import { Props } from "./TextInputInterface";

import "./TextInput.scss";

const TextInput: React.FC<FieldProps & Props> = ({ field, form: { errors }, onBlur, label }: any) => {
    const { name } = field;

    const errorMessage = errors[name];
    const handleBlur = (e) => {
        field.onBlur(e);
        if (onBlur) {
            onBlur(e);
        }
    };

    return (
        <div className={`c-text-input ${errorMessage && "c-text-input--error"}`}>
            <label className="c-text-input__label" htmlFor={name}>
                {label}
            </label>

            <input className="c-text-input__input" type="text" {...field} value={field.value || ""} onBlur={handleBlur} />

            <div className="c-text-input__error">
                <ErrorMessage name={name} />
            </div>
        </div>
    );
};

export default TextInput;
