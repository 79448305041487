import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";

import "./InvoicesList.scss";

import Loader from "components/commons/Loader/Loader";
import InvoiceListHeader from "components/InvoiceListComponents/InvoiceListHeader/InvoiceListHeader";
import InvoiceListSummary from "components/InvoiceListComponents/InvoiceListSummary/InvoiceListSummary";
import InvoiceListTable from "components/InvoiceListComponents/InvoiceListTable/InvoiceListTable";
import InvoicesListStore from "stores/InvoiceListStore";

const InvoicesListRoute = () => {
    const { invoiceStatusesFiltered, invoiceStatuses, total } = InvoicesListStore;

    useEffect(() => {
        if (!invoiceStatuses) {
            InvoicesListStore.getInvoices();
        }
    }, [invoiceStatuses]);

    if (!invoiceStatuses || !invoiceStatusesFiltered || !total) {
        return <Loader />;
    }

    return (
        <div className="c-invoice-list-route">
            <InvoiceListHeader />
            <InvoiceListTable />
            <InvoiceListSummary />
        </div>
    );
};

export default withRouter(observer(InvoicesListRoute));
