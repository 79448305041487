import { observer } from "mobx-react-lite";

import "./EmployeesTable.scss";

import AppStore from "stores/AppStore";
import EmployeeStore from "stores/EmployeeStore";
import EmployeesTableHeader from "./components/EmployeesTableHeader/EmployeesTableHeader";
import EmployeesTableRows from "./components/EmployeesTableRows/EmployeesTableRows";

const EmployeesTable = () => {
    const { employeesFiltered } = EmployeeStore;
    const { invoicing, nonInvoicing } = employeesFiltered;
    const { i18n: {
        employees: {
            nothingToShow,
            invoicingEmployees,
            nonInvoicingEmployees
        }
    } } = AppStore;

    if (!invoicing.length && nonInvoicing.length) {
        return <h4 className="c-employees-table__no-data">{nothingToShow}</h4>;
    }

    const renderInvoicingRows = () => <EmployeesTableRows header={invoicingEmployees} employees={invoicing} />;
    const renderNonInvoicingRows = () => <EmployeesTableRows header={nonInvoicingEmployees} employees={nonInvoicing} />;

    return (
        <table className="c-employees-table">
            <EmployeesTableHeader />
            {!!invoicing.length && renderInvoicingRows()}
            {!!nonInvoicing.length && renderNonInvoicingRows()}
        </table>
    );
};

export default observer(EmployeesTable);
