import React, { useEffect, useState } from "react";

import { Props } from "./ButtonsGroupInterface";

import "./ButtonsGroup.scss";

const ButtonsGroup: React.FC<Props> = ({ buttons, buttonClassName }) => {
    const [active, setActive] = useState("");

    useEffect(() => {
        buttons.forEach((button) => {
            if (button.active) {
                setActive(button.text);
            }
        });
    }, [buttons]);

    const renderButtons = () =>
        buttons.map(({ text, onClick }) => (
            <div
                key={text}
                className={`
                        c-buttons-group__button
                        ${active === text && "c-buttons-group__button--active"}
                        ${buttonClassName}
                    `}
                onClick={() => {
                    setActive(text);
                    onClick();
                }}
            >
                {text}
            </div>
        ));

    return <div className="c-buttons-group">{renderButtons()}</div>;
};

export default ButtonsGroup;
