import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import React, { useState } from "react";

import { Props } from "./CheckboxInterface";

import "./Checkbox.scss";

const Checkbox: React.FC<Props> = ({ onClick, value, label, checked }: Props) => {
    const [isChecked, setIsChecked] = useState(checked);

    const handleClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        setIsChecked(!isChecked);
        onClick(event, { value, isChecked: !isChecked });
    };

    const checkboxClassName = classNames({
        "c-checkbox": true,
        "c-checkbox--checked": isChecked
    });

    return (
        <div className={checkboxClassName} onClick={handleClick}>
            <div className="c-checkbox__square">{isChecked && <FontAwesomeIcon icon={faCheck} />}</div>
            <div className="c-checkbox__label">{label}</div>
        </div>
    );
};

export default Checkbox;
