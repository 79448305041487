import benefits from "api/controllers/Benefits";
import { default as employeeController } from "api/controllers/Employee";
import { default as invoiceModificationsController } from "api/controllers/InvoiceModifications";
import { Employee } from "models/Employee/Employee";
import { Charge } from "models/Invoice/Charge";

import { action, makeObservable, observable } from "mobx";
import { EmployeeData } from "models/Employee/EmployeeData";
import { BenefitsCharges } from "models/Invoice/BenefitsCharges";
import { Company } from "models/Invoice/Company";
import { InvoiceModifications } from "models/Invoice/InvoiceModifications";

class Store {
    @observable public employeeId?: string;
    @observable public employee?: EmployeeData;
    @observable public availableBenefits?: Charge[];
    @observable public benefitsCharges?: BenefitsCharges;
    @observable public invoiceModifications?: InvoiceModifications;

    constructor() {
        makeObservable(this);
    }

    @action public setEmployeeId = (id: string) => {
        this.employeeId = id;
    };

    @action public getEmployeeData = async () => {
        this.employee = await employeeController.getEmployeeData(this.employeeId);
    };

    @action public putCompanyData = async (data: Company) => {
        await employeeController.putCompanyData(this.employeeId, data);
    };

    @action public putEmployeeData = async (data: Employee) => {
        await employeeController.putEmployeeData(this.employeeId, data);
    };

    @action public getEmployeeBenefits = async () => {
        this.benefitsCharges = await benefits.getBenefits(this.employeeId);

        if (!this.availableBenefits) {
            const data = await benefits.getAvailableBenefits();
            this.availableBenefits = data.benefits;
        }
    };

    @action public addBenefit = async (benefitId: number) => {
        const benefitsCharges = await benefits.addBenefit(this.employeeId, { id: benefitId });
        this.benefitsCharges = benefitsCharges;
    };

    @action public deleteBenefit = async (benefitId: number) => {
        const benefitsCharges = await benefits.deleteBenefit(this.employeeId, benefitId);
        this.benefitsCharges = benefitsCharges;
    };

    @action public getEmployeeInvoiceModifications = async () => {
        this.invoiceModifications = await invoiceModificationsController.getInvoiceModifications(this.employeeId);
    };

    @action public addInvoiceModification = async (invoiceModification: any) => {
        const invoiceModifications = await invoiceModificationsController.addInvoiceModification(this.employeeId, invoiceModification);
        this.invoiceModifications = invoiceModifications;
    };

    @action public deleteInvoiceModification = async (id: string) => {
        const invoiceModifications = await invoiceModificationsController.deleteInvoiceModifications(this.employeeId, id);
        this.invoiceModifications = invoiceModifications;
    };

    @action public clearData = () => {
        this.employeeId = undefined;
        this.employee = undefined;
        this.benefitsCharges = undefined;
        this.invoiceModifications = undefined;
    };
}

const EmployeeManagementStore = new Store();

export default EmployeeManagementStore;
