import { observer } from "mobx-react-lite";
import React from "react";

import "./GenerateInvoiceModal.scss";

import Modal from "components/commons/Modal/Modal";
import { getFormattedMonth, getFormattedYear } from "helpers/functions";
import AppStore from "stores/AppStore";
import InvoiceStore from "stores/InvoiceStore";
import { Props } from "./GenerateInvoiceModalInterface";

const GenerateInvoiceModal: React.FC<Props> = ({ isModalVisible, onHideModal }) => {
    const {
        i18n: {
            invoicePreview: { generateInvoiceAndSealTimepot, generateInvoice, beforeContinuing, afterGenerating }
        }
    } = AppStore;

    const { activeMonth } = InvoiceStore;

    return (
        <div className="c-generate-invoice-modal">
            <Modal
                isModalVisible={isModalVisible}
                title={generateInvoiceAndSealTimepot}
                acceptText={generateInvoice}
                onAccept={async () => {
                    await InvoiceStore.generateInvoiceForActiveMonth();
                    onHideModal();
                }}
                onClose={() => {
                    onHideModal();
                }}
            >
                <div className="c-generate-invoice-modal__content">
                    <p className="u-bold">
                        {beforeContinuing} {getFormattedMonth(activeMonth)} {getFormattedYear(activeMonth)}.
                    </p>
                    <p>{afterGenerating}</p>
                </div>
            </Modal>
        </div>
    );
};

export default observer(GenerateInvoiceModal);
