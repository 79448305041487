/* eslint-disable no-unused-vars */
import { Field } from "sparkson";
import { Marker } from "./Marker";

@Marker
export class Settings {
    constructor(
        @Field("auto_generation") public autoGeneration: boolean,
        @Field("hide_sensitive_data") public hideSensitiveData: boolean,
        @Field("invoice_footnote", true) public invoiceFootnote?: string
    ) {}
}
