import { observer } from "mobx-react-lite";
import React from "react";

import { faSyncAlt } from "@fortawesome/free-solid-svg-icons";
import Button from "components/commons/Button/Button";
import AppStore from "stores/AppStore";
import EmployeeStore from "stores/EmployeeStore";
import Search from "../../commons/Search/Search";
import SyncWithTimepot from "./components/SyncButton/SyncWithTimepot";

import "./EmployeeListHeader.scss";

const EmployeeListHeader = () => {
    const {
        i18n: {
            employees: { activeEmployees, searchForEmployees }
        }
    } = AppStore;

    const { searchString, employeesCount } = EmployeeStore;

    return (
        <div className="c-employee-list-header">
            <h3 className="c-employee-list-header__title">
                {activeEmployees} ({employeesCount})
            </h3>
            <div className="c-employee-list-header__right-items">
                <Search
                    searchString={searchString}
                    placeholder={searchForEmployees}
                    onChange={(event) => {
                        EmployeeStore.setSearchString(event.target.value);
                    }}
                />
                <SyncWithTimepot />
                <Button
                    icon={faSyncAlt}
                    className="c-employee-list-header__sync-button"
                    onClick={async () => {
                        EmployeeStore.employees = undefined;
                        await EmployeeStore.getEmployees();
                    }}
                />
            </div>
        </div>
    );
};

export default observer(EmployeeListHeader);
