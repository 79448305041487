/* eslint-disable no-unused-vars */
import { Field } from "sparkson";
import { Marker } from "../Marker";

@Marker
export class Dates {
    constructor(
        @Field("issue") public issue: string,
        @Field("sales") public sales: string,
        @Field("due") public due: string
    ) {}
}
