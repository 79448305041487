import React, { useEffect } from "react";
import { useParams } from "react-router-dom";

import "./EmployeeManagement.scss";

import EmployeeManagement from "components/EmployeeManagementComponents/EmployeeManagement/EmployeeManagement";
import EmployeeManagementHeader from "components/EmployeeManagementComponents/EmployeeManagementHeader/EmployeeManagementHeader";
import EmployeeDataStore from "stores/EmployeeManagementStore";

const EmployeeManagementRoute = () => {
    const { id } = useParams<{ id: string }>();

    EmployeeDataStore.setEmployeeId(id);

    useEffect(() => () => {
        EmployeeDataStore.clearData();
    });

    return (
        <div className="c-employee-management-route">
            <EmployeeManagementHeader />
            <EmployeeManagement />
        </div>
    );
};

export default EmployeeManagementRoute;
