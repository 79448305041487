/* eslint-disable no-unused-vars */
import { Field } from "sparkson";
import { Marker } from "../Marker";
import { InvoiceModificationDetails } from "./InvoiceModificationDetails";

@Marker
export class InvoiceModification {
    constructor(
        @Field("modification_type") public modificationType: "supplement" | "charge",
        @Field("details") public details: InvoiceModificationDetails,
        @Field("id") public id: string,
        @Field("employee_id") public employeeId: string
    ) {}
}
