/* eslint-disable no-unused-vars */
import { Field } from "sparkson";
import { Marker } from "./Marker";
import { Settings } from "./Settings";

@Marker
export class Profile {
    constructor(
        @Field("is_admin") public isAdmin: boolean,
        @Field("is_manager") public isManager: boolean,
        @Field("employment_start_date", true) public employmentStartDate: string,
        @Field("id", true) public id: string,
        @Field("settings") public settings: Settings
    ) {}
}
