import { observer } from "mobx-react-lite";
import React from "react";
import jwt_decode from "jwt-decode";

import logo from "assets/images/logo.svg";
import AppStore from "stores/AppStore";

import "./Login.scss";
import { GoogleLogin } from "@react-oauth/google";
import { UserModel } from "models/User";
import { sparkbill_oauth } from "conf/LocalStorage";

const Login = () => {
    const {
        i18n: {
            login: { text }
        }
    } = AppStore;

    return (
        <div className="c-login">
            <div className="c-login__content">
                <div className="c-login__logo">
                    <img className="c-login__logo-img" src={logo} alt="" />
                </div>

                <div className="c-login__text">{text}</div>

                <GoogleLogin
                    onSuccess={(credentialResponse) => {
                        const decoded: UserModel = jwt_decode(credentialResponse.credential);
                        localStorage.setItem(sparkbill_oauth, credentialResponse.credential);
                        AppStore.setUser(decoded.email, decoded.picture);
                    }}
                    onError={() => {
                        AppStore.setError("Login Failed!");
                    }}
                    useOneTap
                    auto_select
                    hosted_domain="sparkbit.pl"
                />
            </div>
        </div>
    );
};

export default observer(Login);
