import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import React from "react";

import { Props } from "./InfohInterface";

import "./Info.scss";

const Info: React.FC<Props> = ({ text, gray }: Props) => {
    const className = classNames("c-info", {
        "c-info--gray": gray
    });

    return (
        <div className={className}>
            <div className="c-info__icon">
                <FontAwesomeIcon icon={faInfoCircle} />
            </div>
            <div className="c-info__text">{text}</div>
        </div>
    );
};

export default Info;
