import classNames from "classnames";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";

import AppStore from "stores/AppStore";
import { Props } from "./HideSensitiveDataInterface";

import "./HideSensitiveData.scss";

const HideSensitiveData: React.FC<Props> = ({ text }) => {
    const {
        hideSensitiveData,
        i18n: {
            commons: { copiedToClipboard }
        }
    } = AppStore;
    const [showValue, setShowValue] = useState(false);
    const [showLabel, setShowLabel] = useState(false);

    const copyValueToClipboard = () => {
        const input = document.createElement("input");
        document.body.appendChild(input);
        input.value = text as string;
        input.select();
        document.execCommand("copy");
        input.remove();
        setShowLabel(true);

        setTimeout(() => {
            setShowLabel(false);
        }, 3000);
    };

    const getValue = () => {
        if (showValue) {
            return text;
        }

        if (hideSensitiveData) {
            return "XXXXXX";
        }

        return text;
    };

    const className = classNames("c-hide-sensitive-data");

    return (
        <span
            className={className}
            onMouseDown={() => {
                copyValueToClipboard();
                setShowValue(true);
            }}
            onMouseUp={() => {
                setShowValue(false);
            }}
        >
            {showLabel && <div className="c-hide-sensitive-data__label">{copiedToClipboard}</div>}
            {getValue()}
        </span>
    );
};

export default observer(HideSensitiveData);
