import classNames from "classnames";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";

import Loader from "components/commons/Loader/Loader";
import MonthPicker from "components/commons/MonthPicker/MonthPicker";
import Page from "components/commons/Page/Page";
import InvoiceDetails from "components/InvoiceComponents/InvoiceDetails/InvoiceDetails";
import InvoicePreview from "components/InvoiceComponents/InvoicePreview/InvoicePreview";
import moment from "moment";
import AppStore from "stores/AppStore";
import EmployeeInvoiceStore from "stores/EmployeeInvoiceStore";
import InvoiceListStore from "stores/InvoiceListStore";

import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./EmployeeInvoice.scss";

const InvoiceRoute: React.FC<RouteComponentProps<{ id: string; month: string }>> = ({
    match: {
        params: { id, month }
    },
    history
}) => {
    const { invoice, activeMonth, setActiveMonth, setSelectedEmployee, resetStore, resetInvoice, getInvoiceForActiveMonth } = EmployeeInvoiceStore;

    const {
        hideSensitiveData,
        i18n: {
            employees: { backToList },
            invoiceStatuses: { invoiceStatus, missing, issued }
        }
    } = AppStore;

    const [tmpHideSensitiveData] = useState(hideSensitiveData);

    useEffect(() => {
        setActiveMonth(new Date(month));
        setSelectedEmployee(id);

        AppStore.setHideSensitiveData(false);

        getInvoiceForActiveMonth();

        return () => {
            resetStore();
            AppStore.setHideSensitiveData(tmpHideSensitiveData);
        };
    }, []);

    if (!invoice) {
        return <Loader />;
    }

    const handleMonthChange = (date: Date) => {
        resetInvoice();
        setActiveMonth(date);
        getInvoiceForActiveMonth();
    };

    const getMinDate = () => moment("2020-01-01").toDate();

    const getStatus = () => {
        const { status } = invoice.invoiceData;

        const statusClassName = classNames("c-employee-invoice-route__status", {
            [`c-employee-invoice-route__status--${status}`]: status
        });

        return (
            <div className={statusClassName}>
                {invoiceStatus}: {status === "issued" ? issued : missing}
            </div>
        );
    };

    return (
        <div className="c-employee-invoice-route">
            <div className="c-employee-invoice-route__month-picker-wrapper">
                <div
                    className="c-employee-invoice-route__back-arrow"
                    onClick={() => {
                        InvoiceListStore.resetStore();
                        InvoiceListStore.setActiveMonth(activeMonth);
                        history.push("/invoices");
                    }}
                >
                    <div className="c-employee-invoice-route__back-arrow-icon">
                        <FontAwesomeIcon icon={faChevronLeft} />
                    </div>
                    <span>{backToList}</span>
                </div>
                <MonthPicker minDate={getMinDate()} activeMonth={activeMonth} onChange={handleMonthChange} />

                {getStatus()}
            </div>
            <div className="c-employee-invoice-route__content">
                <Page backgroundColor="gray">
                    <InvoiceDetails />
                </Page>
                <Page>
                    <InvoicePreview />
                </Page>
            </div>
        </div>
    );
};

export default withRouter(observer(InvoiceRoute));
