import React from "react";

import { Props } from "./ButtonInlineInterface";

import "./ButtonInline.scss";

const ButtonInline: React.FC<Props> = ({ text, onClick, disabled }) => {
    const handleClick = () => {
        if (!disabled) {
            onClick();
        }
    };

    return (
        <span className="c-button-inline" onClick={handleClick}>
            {text}
        </span>
    );
};

export default ButtonInline;
