/* eslint-disable no-unused-vars */
import { Money } from "models/Invoice/Money";
import { Field } from "sparkson";
import { Marker } from "../Marker";

@Marker
export class Charge {
    constructor(
        @Field("charge") public charge: Money,
        @Field("id") public id: number,
        @Field("family") public family: string,
        @Field("package", true) public packageName?: string
    ) {}
}
