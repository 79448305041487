import React from "react";

import Field from "components/commons/Field/Field";
import Row from "components/commons/Row/Row";
import InvoiceStore from "stores/InvoiceStore";
import HideSensitiveData from "components/commons/HideSensitiveData/HideSensitiveData";
import AppStore from "stores/AppStore";
import BenefitsCharges from "./components/BenefitsCharges/BenefitsCharges";
import ExtraChargesAndSupplements from "./components/ExtraChargesAndSupplements/ExtraChargesAndSupplements";
import TotalAmount from "./components/TotalAmount/TotalAmount";

import "./InvoiceDetails.scss";

const InvoiceDetails: React.FC = () => {
    const {
        invoice: {
            invoiceData: {
                baseSalary: { amount, hourlyRate, timeSpentFormatted, hoursSpentFormatted }
            }
        }
    } = InvoiceStore;

    const {
        i18n: {
            invoiceDetails: { title, workedHours: workedHoursTranslate, hourlyRate: hourlyRateTranslate, amountNet }
        }
    } = AppStore;

    return (
        <div className="c-invoice-details">
            <h3 className="c-invoice-details__h3">{title}</h3>
            <div className="c-invoice-details__content">
                <Row>
                    <Field value={hoursSpentFormatted} additionalValue={timeSpentFormatted} disabled label={workedHoursTranslate} className="c-invoice-details__field" />

                    <span className="c-invoice-details__sign">x</span>

                    <Field value={<HideSensitiveData text={hourlyRate.toString()} />} disabled label={hourlyRateTranslate} className="c-invoice-details__field" />

                    <span className="c-invoice-details__sign">=</span>

                    <Field value={<HideSensitiveData text={amount.toString()} />} label={amountNet} className="c-invoice-details__field" />
                </Row>

                <BenefitsCharges />

                <ExtraChargesAndSupplements />
            </div>

            <div className="c-invoice-details__summary">
                <TotalAmount />
            </div>
        </div>
    );
};

export default InvoiceDetails;
