import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import { observer } from "mobx-react-lite";
import moment from "moment";
import React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";

import "./InvoiceListTableRows.scss";

import AppStore from "stores/AppStore";
import InvoiceListStore from "stores/InvoiceListStore";

const InvoiceListTableRows: React.FC<RouteComponentProps> = ({ history }) => {
    const { invoiceStatusesFiltered, activeMonth } = InvoiceListStore;

    const {
        i18n: {
            employees: { issued, missing },
            invoiceStatuses: { download, sparkbill, sparkbillAuto, upload, net, vat, gross }
        }
    } = AppStore;

    const renderInvoiceSource = (invoiceSource, employeeAutoGenerationSetting) => {
        let source = employeeAutoGenerationSetting ? sparkbillAuto : "";

        const invoiceSourceClassName = classNames({
            "c-invoice-list-table-tbody__auto-invoice-source": !invoiceSource,
            "c-invoice-list-table-tbody__invoice-source": true
        });

        switch (invoiceSource) {
            case "upload":
                source = upload;
                break;
            case "sparkbill-auto":
                source = sparkbillAuto;
                break;
            case "sparkbill":
                source = sparkbill;
                break;
            default:
                break;
        }

        return <span className={invoiceSourceClassName}>{source}</span>;
    };

    const renderInvoiceStatus = (invoiceStatus, invoiceSource, employeeAutoGenerationSetting) => {
        const isIssued = invoiceStatus === "issued";

        const invoiceStatusClassName = classNames({
            "c-invoice-list-table-tbody__issued": isIssued,
            "c-invoice-list-table-tbody__missing": !isIssued
        });

        return (
            <td className="c-invoice-list-table-tbody__td u-align-left">
                <div className="c-invoice-list-table-tbody__invoice-status">
                    <span className={invoiceStatusClassName}>{isIssued ? issued : missing}</span>
                    {renderInvoiceSource(invoiceSource, employeeAutoGenerationSetting)}
                </div>
            </td>
        );
    };

    const renderDownloadButton = (invoiceStatus, employeeId: string) => {
        if (invoiceStatus !== "issued") {
            return <td className="c-invoice-list-table-tbody__td" />;
        }

        return (
            <td
                className="c-invoice-list-table-tbody__td c-invoice-list-table-tbody__download u-align-left"
                onClick={(e) => {
                    e.stopPropagation();
                    InvoiceListStore.downloadInvoice(employeeId);
                }}
            >
                <div className="c-invoice-list-table-tbody__download-icon">
                    <FontAwesomeIcon icon={faDownload} />
                </div>
                <div className="c-invoice-list-table-tbody__download-text">{download}</div>
            </td>
        );
    };

    const renderAmounts = (amounts) => (
        <>
            <td className="c-invoice-list-table-tbody__td c-invoice-list-table-tbody__amount u-align-right">
                <div className="c-invoice-list-table-tbody__amount-value">{amounts.net.toString()}</div>
                <div className="c-invoice-list-table-tbody__amount-description">{net}</div>
            </td>
            <td className="c-invoice-list-table-tbody__td c-invoice-list-table-tbody__amount u-align-right">
                <div className="c-invoice-list-table-tbody__amount-value">{amounts.vat.toString()}</div>
                <div className="c-invoice-list-table-tbody__amount-description">{vat}</div>
            </td>
            <td className="c-invoice-list-table-tbody__td c-invoice-list-table-tbody__amount u-align-right">
                <div className="c-invoice-list-table-tbody__amount-value">{amounts.gross.toString()}</div>
                <div className="c-invoice-list-table-tbody__amount-description">{gross}</div>
            </td>
        </>
    );

    const renderPredictedAmounts = (amounts) => (
        <>
            <td className="c-invoice-list-table-tbody__td c-invoice-list-table-tbody__amount c-invoice-list-table-tbody__predicted-amount u-align-right">
                <div className="c-invoice-list-table-tbody__amount-value">{amounts.net.toString()}</div>
                <div className="c-invoice-list-table-tbody__amount-description">{net}</div>
            </td>
            <td className="c-invoice-list-table-tbody__td c-invoice-list-table-tbody__amount c-invoice-list-table-tbody__predicted-amount u-align-right">
                <div className="c-invoice-list-table-tbody__amount-value">{amounts.vat.toString()}</div>
                <div className="c-invoice-list-table-tbody__amount-description">{vat}</div>
            </td>
            <td className="c-invoice-list-table-tbody__td c-invoice-list-table-tbody__amount c-invoice-list-table-tbody__predicted-amount u-align-right">
                <div className="c-invoice-list-table-tbody__amount-value">{amounts.gross.toString()}</div>
                <div className="c-invoice-list-table-tbody__amount-description">{gross}</div>
            </td>
        </>
    );

    const renderRows = () =>
        invoiceStatusesFiltered.map(({ employeeId, employeeName, invoiceStatus, invoiceSource, amounts, predictedAmounts, employeeAutoGenerationSetting }) => (
            <tr
                className="c-invoice-list-table-tbody__tr"
                key={employeeId}
                onClick={() => {
                    history.push(`invoices/${employeeId}/${moment(activeMonth).format("YYYY-MM")}`);
                }}
            >
                <td className="c-invoice-list-table-tbody__td c-invoice-list-table-tbody__name u-align-left">{employeeName}</td>
                {renderInvoiceStatus(invoiceStatus, invoiceSource, employeeAutoGenerationSetting)}
                {renderDownloadButton(invoiceStatus, employeeId)}
                {amounts && renderAmounts(amounts)}
                {!amounts && renderPredictedAmounts(predictedAmounts)}
            </tr>
        ));

    return <tbody className="c-invoice-list-table-tbody">{renderRows()}</tbody>;
};

export default withRouter(observer(InvoiceListTableRows));
