import { observer } from "mobx-react-lite";
import React from "react";

import AppStore from "stores/AppStore";
import EmployeeStore from "stores/EmployeeStore";

import Button from "components/commons/Button/Button";
import "./SyncWithTimepot.scss";

const SyncWithTimepot = () => {
    const {
        i18n: {
            employees: { syncWithTimepot }
        }
    } = AppStore;

    const syncData = async () => {
        await EmployeeStore.syncWithTimepot();
    };

    return (
        <div className="c-sync-with-timepot">
            <Button className="c-sync-with-timepot__button" text={syncWithTimepot} onClick={syncData} />
        </div>
    );
};

export default observer(SyncWithTimepot);
