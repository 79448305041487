import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";

import "./Invoice.scss";

import Loader from "components/commons/Loader/Loader";
import MonthPicker from "components/commons/MonthPicker/MonthPicker";
import Page from "components/commons/Page/Page";
import InvoiceDetails from "components/InvoiceComponents/InvoiceDetails/InvoiceDetails";
import InvoicePreview from "components/InvoiceComponents/InvoicePreview/InvoicePreview";
import moment from "moment";
import AppStore from "stores/AppStore";
import InvoiceStore from "stores/InvoiceStore";

const InvoiceRoute = () => {
    const { invoice, activeMonth } = InvoiceStore;
    const { employmentStartDate } = AppStore;

    useEffect(() => {
        InvoiceStore.getInvoice();

        return () => {
            InvoiceStore.resetStore();
        };
    }, []);

    if (!invoice) {
        return <Loader />;
    }

    const handleMonthChange = (date: Date) => {
        InvoiceStore.setActiveMonth(date);
        InvoiceStore.getInvoiceForActiveMonth();
    };

    const getMinDate = () => {
        let date = moment("2020-01-01");
        const startDate = moment(employmentStartDate);

        if (startDate.isAfter(date)) {
            date = startDate.subtract(1, "month");
        }

        return date.toDate();
    };

    return (
        <div className="c-invoice-route">
            <div className="c-invoice-route__month-picker-wrapper">
                <MonthPicker minDate={getMinDate()} activeMonth={activeMonth} onChange={handleMonthChange} />
            </div>
            <div className="c-invoice-route__content">
                <Page backgroundColor="gray">
                    <InvoiceDetails />
                </Page>
                <Page>
                    <InvoicePreview />
                </Page>
            </div>
        </div>
    );
};

export default observer(InvoiceRoute);
