import { toBase64 } from "helpers/functions";
import { Invoice as InvoiceModel } from "models/Invoice/Invoice";
import { InvoiceStatuses } from "models/InvoiceStatuses/InvoiceStatuses";

import Controller from "../Controller";

class Invoice extends Controller {
    public getData = async (params?: string): Promise<InvoiceModel> => {
        const url = params ? `/invoice_data?month=${params}` : "/invoice_data";

        const data = await this.get(url);

        return this.parse(InvoiceModel, data);
    };

    public generateInvoice = async (params: string): Promise<InvoiceModel> => {
        const url = `/invoices?month=${params}`;

        const data = await this.post(url, {});

        return this.parse(InvoiceModel, data);
    };

    public uploadInvoice = async (params: string, file: File): Promise<InvoiceModel> => {
        const url = `/external_invoices?month=${params}`;

        const body = {
            invoice_bytes: await toBase64(file)
        };

        const data = await this.post(url, body);

        return this.parse(InvoiceModel, data);
    };

    public getInvoices = async (month?: string): Promise<InvoiceStatuses> => {
        const options = month && {
            params: {
                month
            }
        };

        const data: any = await this.get("/invoicing_status", options);

        return this.parse(InvoiceStatuses, data);
    };

    public downloadInvoice = async (month: string): Promise<any> => {
        const data = await this.downloadFile("/invoice", {
            headers: {
                Accept: "application/pdf"
            },
            params: {
                month
            }
        });

        return data;
    };

    public downloadAllInvoices = async (month: string): Promise<any> => {
        const data = await this.downloadFile("/invoices", {
            headers: {
                Accept: "application/zip"
            },
            params: {
                month
            }
        });

        return data;
    };

    public downloadElixir = async (month: string): Promise<any> => {
        const data = await this.downloadFile("/elixir", {
            headers: {
                Accept: "text/csv"
            },
            params: {
                month
            }
        });

        return data;
    };
}

const invoice = new Invoice("");

export default invoice;
