import { faSyncAlt } from "@fortawesome/free-solid-svg-icons";
import { observer } from "mobx-react-lite";

import "./InvoiceListHeader.scss";

import Button from "components/commons/Button/Button";
import MonthPicker from "components/commons/MonthPicker/MonthPicker";
import Search from "components/commons/Search/Search";
import AppStore from "stores/AppStore";
import InvoiceListStore from "stores/InvoiceListStore";
import Filters from "./components/Filters/Filters";

const InvoiceListHeader = () => {
    const {
        i18n: {
            employees: { searchForEmployees }
        }
    } = AppStore;

    const { activeMonth } = InvoiceListStore;

    const handleMonthChange = async (date: Date) => {
        InvoiceListStore.setActiveMonth(date);
        InvoiceListStore.resetStore();
    };

    return (
        <div className="c-invoice-list-header">
            <MonthPicker minDate={new Date("2020-01-01")} activeMonth={activeMonth} onChange={handleMonthChange} />
            <div className="c-invoice-list-header__right-items">
                <Filters />
                <Search
                    searchString={InvoiceListStore.searchString}
                    placeholder={searchForEmployees}
                    onChange={(event) => {
                        InvoiceListStore.setSearchString(event.target.value);
                    }}
                />
                <Button
                    icon={faSyncAlt}
                    className="c-invoice-list-header__sync-button"
                    onClick={async () => {
                        InvoiceListStore.setInvoiceStatuses(undefined);
                        InvoiceListStore.resetFilters();
                        await InvoiceListStore.getInvoices();
                    }}
                />
            </div>
        </div>
    );
};

export default observer(InvoiceListHeader);
