import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";

import "./BenefitsManagement.scss";

import Loader from "components/commons/Loader/Loader";
import AppStore from "stores/AppStore";
import EmployeeDataStore from "stores/EmployeeManagementStore";
import AddBenefit from "./components/AddBenefit/AddBenefit";
import BenefitsTable from "./components/BenefitsTable/BenefitsTable";

const BenefitsManagement = () => {
    const { benefitsCharges } = EmployeeDataStore;

    const {
        i18n: {
            employees: { monthlyCharges, noActiveBenefits },
            invoiceDetails: { benefitsCharges: benefitsChargesTranslate }
        }
    } = AppStore;

    useEffect(() => {
        EmployeeDataStore.getEmployeeBenefits();
    }, []);

    if (!benefitsCharges) {
        return <Loader />;
    }

    const hasBenefits = !!benefitsCharges.benefits.length;

    const renderBenefitsHeader = () => (
        <div className="c-benefits-management__header">
            <h3 className="c-benefits-management__h3">{benefitsChargesTranslate}</h3>
            <p className="c-benefits-management__p">{monthlyCharges}</p>
        </div>
    );

    const renderNoActiveBenefits = () => <p className="c-benefits-management__no-benefits">{noActiveBenefits}</p>;

    return (
        <>
            <div className="c-benefits-management">
                {renderBenefitsHeader()}
                {!hasBenefits && renderNoActiveBenefits()}
                {hasBenefits && <BenefitsTable />}
                <AddBenefit />
            </div>
        </>
    );
};

export default observer(BenefitsManagement);
