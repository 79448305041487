import { Money } from "models/Invoice/Money";
import { Field } from "sparkson";
import { Marker } from "../Marker";
import { HourlyRate } from "./HourlyRate";

/* eslint-disable no-unused-vars */
@Marker
export class Salary {
    constructor(
        @Field("time_spent_formatted") public timeSpentFormatted: string,
        @Field("hours_spent_formatted") public hoursSpentFormatted: string,
        @Field("minutes_spent") public minutesSpent: number,
        @Field("hourly_rate", true, null, new HourlyRate(0)) public hourlyRate: HourlyRate,
        @Field("amount", true, null, new Money(0)) public amount: Money
    ) {}
}
