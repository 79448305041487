import React from "react";

import "./CompanyDetails.scss";

import { Company } from "models/Invoice/Company";
import AppStore from "stores/AppStore";
import { Props } from "./CompanyDetailsInterface";

const CompanyDetails: React.FC<Props> = ({ company }) => {
    const {
        companyName,
        address: { line1, zipCode, city },
        nip
    } = company;

    const { bankAccountNumber } = company as Company;

    const {
        i18n: {
            invoicePreview: { bankAccountNumber: bankAccountNumberTranslate, nip: nipTranslate }
        }
    } = AppStore;

    const renderBankAccountNumber = () => (
        <div className="c-company-details__bank-account-number">
            <h4>{bankAccountNumberTranslate}</h4>
            <p>{bankAccountNumber}</p>
        </div>
    );

    return (
        <div className="c-company-details">
            <h3 className="u-bold">{companyName}</h3>
            <p>{line1}</p>
            <p>
                {zipCode} {city}
            </p>
            <p>
                {nipTranslate} {nip}
            </p>
            {bankAccountNumber && renderBankAccountNumber()}
        </div>
    );
};

export default CompanyDetails;
