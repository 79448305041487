import React from "react";

import { Props } from "./PageInterface";

import "./Page.scss";

const Page: React.FC<Props> = ({ children, backgroundColor, className, size }) => (
    <div
        className={`c-page
        c-page--${backgroundColor}
        c-page--${size}
        ${className}
      `}
    >
        {children}
    </div>
);

export default Page;
