import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

import { Props } from "./ButtonInterface";

import "./Button.scss";

const Button: React.FC<Props> = ({ text, onClick, icon, className, disabled, cancel, danger }) => {
    const renderIcon = () => (
        <div className="c-button__icon">
            <FontAwesomeIcon icon={icon} />
        </div>
    );

    const handleClick = () => {
        if (!disabled) {
            onClick();
        }
    };

    return (
        <div
            className={`c-button
                ${className}
                ${disabled && "c-button--disabled"}
                ${cancel && "c-button--cancel"}
                ${danger && "c-button--danger"}
            `}
            onClick={handleClick}
        >
            {icon && renderIcon()}
            {text}
        </div>
    );
};

export default Button;
