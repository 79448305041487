import { getServerURL } from "conf/Api";
import { parse, parseArray } from "sparkson";
import AppStore from "stores/AppStore";
import { sparkbill_oauth } from "conf/LocalStorage";

class Controller {
    private path: string;
    private server: string;

    constructor(path: string) {
        this.path = path;
        this.server = getServerURL();
    }

    public get = async (endpoint: string, options?: any) => this.makeRequest(endpoint, "GET", undefined, options);

    public put = async (endpoint: string, body: any, options?: any) => this.makeRequest(endpoint, "PUT", JSON.stringify(body), options);

    public post = async (endpoint: string, body: any, options?: any) => this.makeRequest(endpoint, "POST", JSON.stringify(body), options);

    public delete = async (endpoint: string, options?: any) => this.makeRequest(endpoint, "DELETE", undefined, options);

    public parse = (expectedClass, data): any => {
        try {
            return parse(expectedClass, data);
        } catch (e) {
            console.error(e);
            AppStore.setError(e);
        }
    };

    public parseArray = (expectedClass, data) => {
        try {
            return parseArray(expectedClass, data);
        } catch (e) {
            console.error(e);
            AppStore.setError(e);
        }
    };

    public downloadFile = async (endpoint: string, options?: any): Promise<{ body: ReadableStream<Uint8Array>; headers: any }> => {
        try {
            const url = new URL(`${this.getPath()}${endpoint}`);

            if (options && options.params) {
                url.search = new URLSearchParams(options.params).toString();
            }

            const response = await fetch(url.toString(), {
                headers: this.getHeaders(options),
                method: "GET"
            });

            return {
                body: response.body,
                headers: response.headers
            };
        } catch (error) {
            AppStore.setError(error);
        }
    };

    private getPath = () => `${this.server}${this.path}`;

    private getHeaders = (options?: any) => {
        let headers: any = {
            authorization: localStorage.getItem(sparkbill_oauth)
        };

        if (options && options.headers) {
            headers = {
                ...headers,
                ...options.headers
            };
        } else {
            headers = {
                ...headers,
                "content-type": "application/json"
            };
        }

        return headers;
    };

    private makeRequest = async (endpoint: string, method: string, body?: any, options?: any) => {
        const url = new URL(`${this.getPath()}${endpoint}`);

        if (options && options.params) {
            url.search = new URLSearchParams(options.params).toString();
        }
        try {
            const response = await fetch(url.toString(), {
                body,
                headers: this.getHeaders(options),
                method
            });

            if (response.status === 403) {
                localStorage.removeItem(sparkbill_oauth);
                AppStore.setUserStatus("loggedOut");
                window.location.href = "/";
            }
            return await response.json();
        } catch (error) {
            return {};
        }
    };
}

export default Controller;
