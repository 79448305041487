import React from "react";

import "./ModificationsRow.scss";

import Button from "components/commons/Button/Button";
import { useComponentVisible } from "helpers/hooks/useComponentVisible";
import { InvoiceModification } from "models/Invoice/InvoiceModification";
import AppStore from "stores/AppStore";
import EmployeeDataStore from "stores/EmployeeManagementStore";

const ModificationsRow: React.FC<{ invoiceModification: InvoiceModification }> = ({ invoiceModification }) => {
    const {
        i18n: {
            employees: { removeModification, cancel, remove, charge, supplement }
        }
    } = AppStore;

    const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);

    const { id, details, modificationType } = invoiceModification;

    const { description, amount } = details;

    const handleCancel = () => {
        setIsComponentVisible(false);
    };

    const handleRemove = async () => {
        await EmployeeDataStore.deleteInvoiceModification(id);

        setIsComponentVisible(false);
    };

    const getModificationType = () => {
        switch (modificationType) {
            case "charge":
                return charge;
            case "supplement":
                return supplement;
            default:
                return modificationType;
        }
    };

    const getDescription = () => <div className="c-modifications-row__description-row">{description}</div>;

    const renderRemoveModal = () => (
        <div className="c-modifications-row__modal" ref={ref}>
            <div className="c-modifications-row__modal-header">
                <h4>{removeModification}</h4>
            </div>
            <div className="c-modifications-row__modal-buttons">
                <Button className="c-modifications-row__modal-button" text={cancel} cancel onClick={handleCancel} />
                <Button danger className="c-modifications-row__modal-button" text={remove} onClick={handleRemove} />
            </div>
        </div>
    );

    return (
        <tr className="c-modifications-row">
            <td className="c-modifications-row__td">
                <h4
                    className={`c-modifications-row__h4
                    c-modifications-row__h4--${modificationType}`}
                >
                    {getModificationType()}
                </h4>
                <div className="c-modifications-row__description">{getDescription()}</div>
            </td>
            <td className="c-modifications-row__td c-modifications-row__amount u-align-right">{amount.toString()}</td>
            <td className="c-modifications-row__td c-modifications-row__td-remove">
                <span
                    className="c-modifications-row__remove"
                    onClick={() => {
                        setIsComponentVisible(true);
                    }}
                >
                    x
                </span>
                {isComponentVisible && renderRemoveModal()}
            </td>
        </tr>
    );
};
export default ModificationsRow;
