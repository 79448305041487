import { observer } from "mobx-react-lite";
import React, { useState } from "react";

import Button from "components/commons/Button/Button";
import AppStore from "stores/AppStore";
import AddChargeSupplementForm from "../AddModificationForm/AddModificationForm";

import "./AddModification.scss";

const AddModification = () => {
    const {
        i18n: {
            employees: { addModification }
        }
    } = AppStore;

    const [isAdding, setIsAdding] = useState(false);

    const [formType, setFormType] = useState<string>(undefined);

    const handleButtonClick = (type) => {
        setFormType(type);
        setIsAdding(true);
    };

    const handleCancel = () => {
        setIsAdding(false);
        setFormType(undefined);
    };

    const forms = [
        {
            buttonText: addModification,
            form: <AddChargeSupplementForm onCancel={handleCancel} onSubmit={handleCancel} />,
            type: "add-modification"
        }
    ];

    const renderAddButtons = () =>
        forms.map(({ buttonText, type }) => (
            <div className="c-add-modification__button" key={type}>
                <Button
                    text={buttonText}
                    onClick={() => {
                        handleButtonClick(type);
                    }}
                />
            </div>
        ));

    const renderAddForm = () => <div className="c-add-modification__form">{forms.filter(({ type }) => type === formType)[0].form}</div>;

    return (
        <div className="c-add-modification">
            {!isAdding && renderAddButtons()}
            {isAdding && renderAddForm()}
        </div>
    );
};

export default observer(AddModification);
