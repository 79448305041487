/* eslint-disable no-unused-vars */
import { Field } from "sparkson";
import { Marker } from "../Marker";

@Marker
export class Address {
    constructor(
        @Field("line1", true, null, "") public line1: string,
        @Field("zip_code", true, null, "") public zipCode: string,
        @Field("city", true, null, "") public city: string
    ) {}
}
