import moment from "moment";
import AppStore from "stores/AppStore";

export const groupBy = (items, key) =>
    items.reduce(
        (result, item) => ({
            ...result,
            [item[key]]: [...(result[item[key]] || []), item]
        }),
        {}
    );

export const nth = (n) => ["st", "nd", "rd"][((((n + 90) % 100) - 10) % 10) - 1] || "th";

export const toBase64 = (file) =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            let encoded = reader.result.toString().replace(/^data:(.*,)?/, "");
            if (encoded.length % 4 > 0) {
                encoded += "=".repeat(4 - (encoded.length % 4));
            }
            resolve(encoded);
        };
        reader.onerror = (error) => reject(error);
    });

export const getFormattedDate = (date: Date) => moment(date).format("YYYY-MM-DD");

export const getFormattedMonth = (date: Date) => {
    const {
        i18n: {
            invoicePreview: { months }
        }
    } = AppStore;

    return months[moment(date).format("M")];
};

export const getFormattedYear = (date: Date) => moment(date).format("YYYY");
