export const en = {
    commons: {
        changeSettings: "Change settings",
        copiedToClipboard: "Copied to clipboard",
        day: "Day",
        done: "Done",
        invalidBankAccountNumber: "Invalid bank account number",
        invalidNipNumber: "Invalid NIP",
        invalidZipCode: "Invalid zip code",
        month: "Month",
        pleaseTryToRestartApp: "please try to restart app",
        settings: "Settings",
        somethingWentWrong: "Something went wrong",
        year: "Year",
        youCanEnableAutomaticInvoiceIssuing: "You can enable automatic invoice issuing. "
    },
    employees: {
        activeEmployees: "Active employees",
        add: "add",
        addBenefit: "add benefit",
        addModification: "add modification",
        address: "Address",
        all: "All",
        backToList: "back to list",
        cancel: "cancel",
        charge: "Charge",
        chooseBenefit: "Choose benefit",
        chooseEmployee: "Choose employee",
        choosePackage: "Choose package",
        city: "City",
        companyName: "Company name",
        destinationCountry: "Destination country",
        employeeRecommendation: "Employee recommendation",
        employmentStart: "Employment start",
        employmentStartDate: "Employment start date",
        fieldCantBeEmpty: "Field can't be empty",
        id: "Id",
        isInvoicing: "Is invoicing",
        invoicingEmployees: "Invoicing employees",
        nonInvoicingEmployees: "Non-invoicing employees",
        issued: "Issued",
        lastPeriodInvoiceStatus: "Last period invoice status",
        missing: "Missing",
        modificationsAwaitingSettling: "Modifications awaiting settling",
        monthlyCharges: "Monthly charges",
        name: "Name",
        nextInvoiceModifications: "Next invoice modifications",
        noActiveBenefits: "No active benefits.",
        noExtraModifications: "No extra modifications.",
        notWorkingDaysAllowance: "Not working days allowance",
        nothingToShow: "Nothing to show",
        pleaseEnterValue: "Please enter value",
        pleaseProvideAmount: "Please provide amount",
        pleaseProvideDescription: "Please provide description",
        pleaseSelectType: "Please select type",
        pleaseSelectValue: "Please select value",
        postcode: "Postcode",
        remove: "remove",
        removeBenefitPackage: "Remove benefit package?",
        removeModification: "Remove modification?",
        save: "save",
        searchForEmployees: "Search for employees",
        selectDestinationCountry: "Select destination country",
        selectEndDate: "Select end date",
        selectStartDate: "Select start date",
        selectTransferDate: "Select transfer date",
        serviceName: "Service name",
        supplement: "Supplement",
        syncWithTimepot: "sync with timepot",
        transferDate: "Transfer date",
        tripBetweenWarsawAndLublin: "Trip between Warsaw and Lublin offices. both ways.",
        vatExemption: "VAT exemption",
        workingDaysAllowance: "Working days allowance"
    },
    invoiceDetails: {
        amountGross: "Amount (gross)",
        amountNet: "Amount (net)",
        benefit: "Benefit",
        benefitsCharges: "Benefits charges",
        charge: "Charge",
        description: "Description",
        extraChargesAndSupplements: "Extra charges and supplements",
        healthcare: "Allianz healthcare",
        hourlyRate: "Hourly rate",
        multisport: "Multisport card",
        package: "Package",
        title: "Invoice details",
        total: "Total",
        totalCharge: "Total charge",
        totalExtras: "Total extras",
        type: "Type",
        vatAmount: "VAT amount",
        workedHours: "Worked hours"
    },
    invoicePreview: {
        afterGenerating: "After generating the invoice you will not be able to modify the data.",
        bankAccountNumber: "Bank account number",
        beforeContinuing: "Before continuing please make sure that your Timepot is complete for",
        client: "Client",
        company: "Company",
        companyDetails: "Company details",
        currentMonth: "current month",
        forImprovedPrivacy: "For improved privacy your hourly rate and invoice amounts will be hidden in Your invoice screen. To see the value keep the mouse button pressed over it.",
        generateInvoice: "Generate invoice",
        generateInvoiceAndSealTimepot: "Generate invoice and seal Timepot",
        generateInvoicesAutomatically: "Generate invoices automatically",
        hideSensitiveData: "Hide sensitive data",
        invoiceIssued: "Invoice issued.",
        invoiceFooter: "Invoice footer",
        optionalTextAtTheBottom: "Optional text that will appear at the bottom of generated invoices.",
        issueDate: "Issue date",
        months: {
            1: "January",
            2: "February",
            3: "March",
            4: "April",
            5: "May",
            6: "June",
            7: "July",
            8: "August",
            9: "September",
            10: "October",
            11: "November",
            12: "December"
        },
        nip: "NIP",
        paymentDueDate: "Payment due date",
        pln: "PLN",
        previousMonth: "previous month",
        salesDate: "Sales date",
        selectOnlyOneFile: "Select only one file.",
        theInvoiceWillBeIssued: "The invoice will be issued automatically at 15:00 on the first working day of the month.",
        thisTypeIsNotSupported: "This type is not supported.",
        timepotFor: "Timepot for the previous month must be complete by then.",
        totalToBePaid: "Total to be paid",
        uploadFromDisk: "upload from disk",
        uploadInvoice: "Upload invoice and seal Timepot",
        weAreProcessingYourInvoice: "We're processing your invoice",
        weWillSendYouSlackMessage: "We'll send you slack message with the result"
    },
    invoiceStatuses: {
        allSettled: "All settled",
        amountsPLN: "Amounts (PLN)",
        download: "Download",
        downloadAllInvoices: "download all invoices",
        downloadElixirFile: "download elixir file",
        downloadInvoice: "Download invoice",
        predicted: "Predicted",
        generated: "Generated",
        gross: "gross",
        invoiceStatus: "Invoice status",
        invoicesFor: "Invoices for",
        issued: "Issued",
        missing: "Missing",
        missingSparkbillAuto: "Missing (Sparkbill Auto)",
        net: "net",
        sparkbill: "Sparkbill",
        sparkbillAuto: "Sparkbill (Auto)",
        upload: "Upload",
        vat: "vat"
    },
    login: {
        signInWithGoogle: "sign in with google",
        text: "Sparkbill assists Sparkbit team members with issuing monthly invoices. It should be used for checking invoice amount and for generating or uploading invoices in PDF format."
    },
    menu: {
        employees: "Employees",
        invoices: "Invoices",
        signOut: "sign out",
        yourInvoice: "Your invoice"
    }
};
