import moment from "moment";

import Controller from "api/Controller";
import { Employee as EmployeeModel } from "models/Employee/Employee";
import { EmployeeData } from "models/Employee/EmployeeData";
import { Employees as EmployeesModel } from "models/Employee/Employees";
import { Company } from "models/Invoice/Company";
import { Invoice as InvoiceModel } from "models/Invoice/Invoice";

class Employee extends Controller {
    public getEmployees = async (): Promise<EmployeesModel> => {
        const data: any = await this.get("");

        return this.parse(EmployeesModel, data);
    };

    public getEmployeeData = async (id: string): Promise<EmployeeData> => {
        const data = await this.get(`/${id}`);

        return this.parse(EmployeeData, data);
    };

    public getEmployeeInvoice = async (id: string, activeMonth: Date): Promise<InvoiceModel> => {
        const data = await this.get(`/${id}/invoice_data?month=${moment(activeMonth).format("YYYY-MM")}`);

        return this.parse(InvoiceModel, data);
    };

    public syncWithTimepot = async (): Promise<void> => {
        await this.post("_sync", {});
    };

    public putCompanyData = async (id: string, body: Company): Promise<any> => {
        const data = await this.put(`/${id}/company_data`, body);

        return data;
    };

    public putEmployeeData = async (id: string, body: EmployeeModel): Promise<any> => {
        const data = await this.put(`/${id}`, body);

        return data;
    };

    public downloadInvoice = async (employeeId: string, month: string): Promise<any> => {
        const data = await this.downloadFile(`/${employeeId}/invoice`, {
            headers: {
                Accept: "application/pdf"
            },
            params: {
                month
            }
        });

        return data;
    };
}

const employee = new Employee("/employees");

export default employee;
