import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";

import "./EmployeeList.scss";

import Loader from "components/commons/Loader/Loader";
import EmployeeListHeader from "components/EmployeeListComponents/EmployeeListHeader/EmployeeListHeader";
import EmployeesTable from "components/EmployeeListComponents/EmployeeListTable/EmployeesTable";
import EmployeeStore from "stores/EmployeeStore";

const EmployeeListRoute = observer(() => {
    const { employees } = EmployeeStore;

    useEffect(() => {
        EmployeeStore.getEmployees();
    }, []);

    if (!employees) {
        return <Loader />;
    }

    return (
        <div className="c-employee-list-route">
            <EmployeeListHeader />
            <EmployeesTable />
        </div>
    );
});

export default withRouter(EmployeeListRoute);
