import { Profile as ProfileModel } from "models/Profile";
import Controller from "../Controller";

class Profile extends Controller {
    public getProfile = async (): Promise<ProfileModel> => {
        const data = await this.get("");

        return this.parse(ProfileModel, data);
    };
}

const profile = new Profile("/profile");

export default profile;
