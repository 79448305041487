import { action, computed, makeObservable, observable } from "mobx";

import employee from "api/controllers/Employee";
import { Employee } from "models/Employee/Employee";

class Store {
    @observable public employees?: Employee[];
    @observable public employeesCount?: number;
    @observable public searchString?: string;

    constructor() {
        makeObservable(this);
    }

    @computed get employeesFiltered() {
        let employees = [...this.employees];

        if (this.searchString) {
            employees = employees.filter(({ name }) => name.toLowerCase().includes(this.searchString));
        }

        const invoicing = employees.filter((e) => e.isInvoicing);
        const nonInvoicing = employees.filter((e) => !e.isInvoicing);

        return {
            invoicing,
            nonInvoicing
        };
    }

    @action public syncWithTimepot = async () => {
        await employee.syncWithTimepot();
        await this.getEmployees();
    };

    @action public getEmployees = async () => {
        const data = await employee.getEmployees();
        this.employees = data.employees;
        this.employeesCount = data.employeeCount;
    };

    @action public setSearchString = async (searchString: string) => {
        this.searchString = searchString;
    };
}

const EmployeeStore = new Store();

export default EmployeeStore;
