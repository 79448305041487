/* eslint-disable no-unused-vars */
import { Field } from "sparkson";
import { Marker } from "../Marker";
import { Tenure } from "./Tenure";

@Marker
export class Employee {
    constructor(
        @Field("id") public id: string,
        @Field("employment_start_date") public employmentStartDate: string,
        @Field("tenure") public tenure: Tenure,
        @Field("name") public name: string,
        @Field("is_invoicing", true) public isInvoicing: boolean
    ) { }
}
