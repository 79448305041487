import { observer } from "mobx-react-lite";
import React from "react";

import "./ModificationsTable.scss";

import AppStore from "stores/AppStore";
import EmployeeDataStore from "stores/EmployeeManagementStore";
import ModificationsRow from "../ModificationsRow/ModificationsRow";

const ModificationsTable = () => {
    const {
        i18n: {
            invoiceDetails: { benefit, package: packageTranslate }
        }
    } = AppStore;

    const {
        invoiceModifications: { invoiceModifications }
    } = EmployeeDataStore;

    const renderRows = () => invoiceModifications.map((invoiceModification) => <ModificationsRow key={invoiceModification.id} invoiceModification={invoiceModification} />);

    return (
        <table className="c-modifications-table">
            <thead>
                <tr>
                    <th className="c-modifications-table__th u-align-left">{benefit}</th>
                    <th className="c-modifications-table__th u-align-right">{packageTranslate}</th>
                    <th className="c-modifications-table__th" />
                </tr>
                <tr className="c-modifications-table__divider">
                    <td className="c-modifications-table__divider-td" />
                </tr>
            </thead>
            <tbody>{renderRows()}</tbody>
        </table>
    );
};

export default observer(ModificationsTable);
