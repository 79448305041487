import { observer } from "mobx-react-lite";
import React from "react";

import { useComponentVisible } from "helpers/hooks/useComponentVisible";
import AppStore from "stores/AppStore";

import "./User.scss";
import { sparkbill_oauth } from "conf/LocalStorage";

const User = () => {
    const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);

    const {
        user:
            { email, picture }
    } = AppStore;

    const renderUserModal = () => (
        <div ref={ref} className="c-user__modal">
            <div className="c-user__modal-icon">
                <img className="c-user__icon-picture" src={picture} alt={email} />
            </div>
            <p className="c-user__email">{email}</p>
            <div
                className="c-user__sign-out"
                onClick={() => {
                    AppStore.setUserStatus("loggedOut");
                    localStorage.removeItem(sparkbill_oauth);
                }}
            >
                sign out
            </div>
        </div>
    );

    return (
        <div className="c-user">
            <div
                className="c-user__icon"
                onClick={() => {
                    setIsComponentVisible(!isComponentVisible);
                }}
            >
                <img className="c-user__icon-picture" src={picture} alt={email} />
            </div>

            {isComponentVisible && renderUserModal()}
        </div>
    );
};

export default observer(User);
