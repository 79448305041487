import { observer } from "mobx-react-lite";
import React from "react";

import "./Filters.scss";

import ButtonsGroup from "components/commons/ButtonsGroup/ButtonsGroup";
import Checkbox from "components/commons/Checkbox/Checkbox";
import { ButtonGroupModel } from "models/Components/ButtonGroupModel";
import AppStore from "stores/AppStore";
import InvoiceListStore from "stores/InvoiceListStore";

const Filters = () => {
    const {
        invoiceStatus,
        issued: { count: issuedCount },
        missing: { count: missingCount },
        total: { count: totalCount }
    } = InvoiceListStore;
    const {
        i18n: {
            employees: { issued, missing, all },
            invoiceStatuses: { upload, sparkbillAuto, sparkbill, missing: missingTranslate, missingSparkbillAuto }
        }
    } = AppStore;

    const buttons: ButtonGroupModel[] = [
        {
            active: invoiceStatus === "issued",
            onClick: () => {
                InvoiceListStore.setInvoiceStatus("issued");
            },
            text: `${issued} (${issuedCount})`
        },
        {
            active: invoiceStatus === "missing",
            onClick: () => {
                InvoiceListStore.setInvoiceStatus("missing");
            },
            text: `${missing} (${missingCount})`
        },
        {
            active: invoiceStatus === undefined,
            onClick: () => {
                InvoiceListStore.setInvoiceStatus(undefined);
            },
            text: `${all} (${totalCount})`
        }
    ];

    const handleIssuedCheckboxChange = (event, { value, isChecked }) => {
        if (isChecked) {
            InvoiceListStore.addSource(value);
        } else {
            InvoiceListStore.deleteSource(value);
        }
    };

    const handleMissingCheckboxChange = (event, { value, isChecked }) => {
        if (isChecked) {
            InvoiceListStore.addMissingSource(value);
        } else {
            InvoiceListStore.deleteMissingSource(value);
        }
    };

    const renderIssuedSourceCheckboxes = () => (
        <div className="c-filters__source-checkboxes">
            <div className="c-filters__source-checkbox">
                <Checkbox checked={InvoiceListStore.source.has("sparkbill")} onClick={handleIssuedCheckboxChange} label={sparkbill} value="sparkbill" />
            </div>
            <div className="c-filters__source-checkbox">
                <Checkbox checked={InvoiceListStore.source.has("sparkbill-auto")} onClick={handleIssuedCheckboxChange} label={sparkbillAuto} value="sparkbill-auto" />
            </div>
            <div className="c-filters__source-checkbox">
                <Checkbox checked={InvoiceListStore.source.has("upload")} onClick={handleIssuedCheckboxChange} label={upload} value="upload" />
            </div>
        </div>
    );

    const renderMissingSourceCheckboxes = () => (
        <div className="c-filters__source-checkboxes">
            <div className="c-filters__source-checkbox">
                <Checkbox checked={InvoiceListStore.missingSource.has("missing")} onClick={handleMissingCheckboxChange} label={missingTranslate} value="missing" />
            </div>
            <div className="c-filters__source-checkbox">
                <Checkbox checked={InvoiceListStore.missingSource.has("missing-sparkbill-auto")} onClick={handleMissingCheckboxChange} label={missingSparkbillAuto} value="missing-sparkbill-auto" />
            </div>
        </div>
    );

    return (
        <div className="c-filters">
            <ButtonsGroup buttons={buttons} buttonClassName="c-filters__button" />
            {invoiceStatus === "issued" && renderIssuedSourceCheckboxes()}
            {invoiceStatus === "missing" && renderMissingSourceCheckboxes()}
        </div>
    );
};

export default observer(Filters);
