import { faCog } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { observer } from "mobx-react-lite";
import React from "react";

import AppStore from "stores/AppStore";
import SettingsModal from "./components/SettingsModal/SettingsModal";

import "./Settings.scss";

const Settings = () => {
    const { setIsSettingsModalVisible, isSettingsModalVisible } = AppStore;

    return (
        <>
            <div
                className="c-settings"
                onClick={() => {
                    setIsSettingsModalVisible(true);
                }}
            >
                <div className="c-settings__icon">
                    <FontAwesomeIcon size="lg" icon={faCog} />
                </div>
            </div>
            {isSettingsModalVisible && <SettingsModal />}
        </>
    );
};

export default observer(Settings);
