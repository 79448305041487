import { faCheckCircle, faDownload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import { observer } from "mobx-react-lite";
import React from "react";

import Button from "components/commons/Button/Button";
import { Money } from "models/Invoice/Money";
import AppStore from "stores/AppStore";
import InvoiceListStore from "stores/InvoiceListStore";
import "./InvoiceListSummary.scss";

const InvoiceListSummary = () => {
    const {
        i18n: {
            invoiceStatuses: { downloadAllInvoices, downloadElixirFile, issued, predicted, allSettled, net, gross, vat }
        }
    } = AppStore;

    const {
        total: { amounts: totalAmounts },
        missing: { count: missingCount },
        issued: { amounts: issuedAmounts }
    } = InvoiceListStore;

    const renderAmount = ({ amount, text, isGross }: { amount: Money; text?: string; isGross?: boolean }) => {
        const valueClassName = classNames({
            "c-invoice-list-summary__amount-gross": isGross,
            "c-invoice-list-summary__amount-value": true
        });

        return (
            <div className="c-invoice-list-summary__amount u-align-right">
                <div className={valueClassName}>{amount.toString()}</div>
                <div className="c-invoice-list-summary__amount-description">{text}</div>
            </div>
        );
    };

    const renderTotalAmounts = () => (
        <div className="c-invoice-list-summary__row c-invoice-list-summary__total-row">
            <div className="c-invoice-list-summary__row-label c-invoice-list-summary__total-row-label">
                <div className="c-invoice-list-summary__icon">
                    <FontAwesomeIcon icon={faCheckCircle} />
                </div>
                {allSettled}
            </div>
            {renderAmount({ text: net, amount: totalAmounts.net })}
            {renderAmount({ text: vat, amount: totalAmounts.vat })}
            {renderAmount({ text: gross, amount: totalAmounts.gross, isGross: true })}
        </div>
    );

    const renderCurrentAmounts = () => (
        <div className="c-invoice-list-summary__row c-invoice-list-summary__current-row">
            <div className="c-invoice-list-summary__row-label">{issued}</div>
            {renderAmount({ amount: issuedAmounts.net })}
            {renderAmount({ amount: issuedAmounts.vat })}
            {renderAmount({ amount: issuedAmounts.gross, isGross: true })}
        </div>
    );

    const renderPredictedAmounts = () => (
        <div className="c-invoice-list-summary__row c-invoice-list-summary__predicted-row">
            <div className="c-invoice-list-summary__row-label">{predicted}</div>
            {renderAmount({ text: net, amount: totalAmounts.net })}
            {renderAmount({ text: vat, amount: totalAmounts.vat })}
            {renderAmount({ text: gross, amount: totalAmounts.gross })}
        </div>
    );

    const handleDownloadAllInvoices = () => {
        InvoiceListStore.downloadAllInvoices();
    };

    const handleDownloadElixirFile = () => {
        InvoiceListStore.downloadElixir();
    };

    return (
        <div className="c-invoice-list-summary">
            <div className="c-invoice-list-summary__left-items">
                <Button className="c-invoice-list-summary__download-button" icon={faDownload} text={downloadAllInvoices} onClick={handleDownloadAllInvoices} />
                <Button className="c-invoice-list-summary__download-button" icon={faDownload} text={downloadElixirFile} onClick={handleDownloadElixirFile} />
            </div>

            <div className="c-invoice-list-summary__right-items">
                {!missingCount && renderTotalAmounts()}
                {!!missingCount && renderCurrentAmounts()}
                {!!missingCount && renderPredictedAmounts()}
            </div>
        </div>
    );
};

export default observer(InvoiceListSummary);
