import React from "react";

import "./Error.scss";

import { observer } from "mobx-react-lite";
import AppStore from "stores/AppStore";

const Error: React.FC = () => {
    const {
        i18n: {
            commons: { pleaseTryToRestartApp, somethingWentWrong }
        }
    } = AppStore;

    return (
        <div className="c-error">
            <h1 className="c-error__h1">{somethingWentWrong}</h1>
            <h3 className="c-error__h3">{pleaseTryToRestartApp}</h3>
        </div>
    );
};

export default observer(Error);
