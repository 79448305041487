/* eslint-disable no-unused-vars */
import { Money } from "models/Invoice/Money";
import { Field } from "sparkson";
import { Marker } from "../Marker";

@Marker
export class Amounts {
    constructor(
        @Field("net", true, null, new Money(0)) public net: Money,
        @Field("vat", true, null, new Money(0)) public vat: Money,
        @Field("gross", true, null, new Money(0)) public gross: Money
    ) {}
}
