import { Settings as SettingsModel } from "models/Settings";
import Controller from "../Controller";

class Settings extends Controller {
    public setSettings = async (body: any): Promise<SettingsModel> => {
        const data = await this.put("", body);

        return this.parse(SettingsModel, data);
    };
}

const settings = new Settings("/settings");

export default settings;
