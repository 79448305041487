import React from "react";

import "./Loader.scss";

const Loader: React.FC = () => (
    <div className="c-loader">
        <div className="c-loader__lds-ellipsis">
            <div />
            <div />
            <div />
            <div />
        </div>
    </div>
);

export default Loader;
