import classNames from "classnames";
import { FieldProps } from "formik";
import React from "react";

import ButtonsGroup from "components/commons/ButtonsGroup/ButtonsGroup";
import { Props } from "./ButtonsGroupInputInterface";

import "./ButtonsGroupInput.scss";

const ButtonsGroupInput: React.FC<FieldProps & Props> = ({ field, form: { setFieldValue, errors, touched }, buttons, buttonClassName, className: optionalClassName }) => {
    const { name } = field;

    const isTouched = touched[name];
    const errorMessage = isTouched && errors[name];

    const className = classNames("c-buttons-group-input", {
        [optionalClassName]: optionalClassName
    });

    buttons.forEach((button) => {
        button.onClick = () => {
            setFieldValue(name, button.value);
        };
    });

    return (
        <div className={className}>
            <ButtonsGroup buttons={buttons} buttonClassName={buttonClassName} />
            <div className="c-buttons-group-input__error">{errorMessage}</div>
        </div>
    );
};

export default ButtonsGroupInput;
