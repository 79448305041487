import { Field, Form, Formik, FormikProps } from "formik";
import { observer } from "mobx-react-lite";
import React from "react";

import CheckboxInput from "components/commons/Formik/CheckboxInput/CheckboxInput";
import TextInput from "components/commons/Formik/TextInput/TextInput";
import { isBankAccountNumberValid, isNipValid, isZipCodeValid, notEmpty } from "helpers/validators";
import { Company } from "models/Invoice/Company";
import AppStore from "stores/AppStore";
import EmployeeDataStore from "stores/EmployeeManagementStore";

import "./CompanyDataForm.scss";

const DataForm: React.FC<Company & FormikProps<Company>> = ({ submitForm, isValid }) => {
    const handleBlur = () => {
        if (isValid) {
            submitForm();
        }
    };

    const {
        i18n: {
            employees: { companyName, address, city, postcode, vatExemption, fieldCantBeEmpty, serviceName },
            invoicePreview: { nip, bankAccountNumber, companyDetails }
        }
    } = AppStore;

    return (
        <Form>
            <h4 className="c-company-data-form__header">{companyDetails}</h4>

            <Field name="companyName" label={companyName} component={TextInput} validate={notEmpty(fieldCantBeEmpty)} onBlur={handleBlur} />
            <Field name="nip" label={nip} component={TextInput} validate={isNipValid} onBlur={handleBlur} />
            <Field name="bankAccountNumber" label={bankAccountNumber} component={TextInput} validate={isBankAccountNumberValid} onBlur={handleBlur} />
            <Field name="address.line1" label={address} component={TextInput} validate={notEmpty(fieldCantBeEmpty)} onBlur={handleBlur} />
            <Field name="address.city" label={city} component={TextInput} validate={notEmpty(fieldCantBeEmpty)} onBlur={handleBlur} />
            <Field name="address.zipCode" label={postcode} component={TextInput} validate={isZipCodeValid} onBlur={handleBlur} />
            <Field name="serviceName" label={serviceName} component={TextInput} validate={notEmpty(fieldCantBeEmpty)} onBlur={handleBlur} />
            <Field name="vatExemption" label={vatExemption} component={CheckboxInput} onChange={handleBlur} />
        </Form>
    );
};

const CompanyDataForm = () => {
    const {
        employee: { companyData }
    } = EmployeeDataStore;

    const mapValues = (values: Company) => ({
        address: {
            city: values.address.city,
            line1: values.address.line1,
            zip_code: values.address.zipCode
        },
        bank_account_number: values.bankAccountNumber,
        company_name: values.companyName,
        nip: values.nip,
        service_name: values.serviceName,
        vat_exemption: !!values.vatExemption
    });

    const handleSubmit = (values) => {
        EmployeeDataStore.putCompanyData(mapValues(values) as any);
    };

    return (
        <div className="c-company-data-form">
            <Formik initialValues={companyData} onSubmit={handleSubmit} component={DataForm} />
        </div>
    );
};

export default observer(CompanyDataForm);
