/* eslint-disable no-unused-vars */
import { Field } from "sparkson";
import { Marker } from "../Marker";
import { InvoiceData } from "./InvoiceData";

@Marker
export class Invoice {
    constructor(
        @Field("month") public month: string,
        @Field("invoice_data") public invoiceData: InvoiceData
    ) {}
}
