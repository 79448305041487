import { Registrable } from "sparkson";
import AppStore from "stores/AppStore";

@Registrable
export class Tenure {
    public value: string;

    constructor(value: string) {
        this.value = value;
    }

    public toString() {
        return `${this.getYear()} ${this.getMonth()} ${this.getDay()}`;
    }

    private getYear = () => {
        const value = Number(this.value.slice(0, this.value.indexOf("y")));
        const translation = AppStore.i18n.commons.year;

        return this.getStringValue(value, translation);
    }

    private getMonth = () => {
        const value = Number(this.value.slice(this.value.indexOf("y") + 1, this.value.indexOf("m")));
        const translation = AppStore.i18n.commons.month;

        return this.getStringValue(value, translation);
    }

    private getDay = () => {
        const value = Number(this.value.slice(this.value.indexOf("m") + 1, this.value.indexOf("d")));
        const translation = AppStore.i18n.commons.day;

        return this.getStringValue(value, translation);
    }

    private getStringValue = (value: number, translation: string) => {
        if (value === 0) {
            return "";
        }

        if (value > 1) {
            translation += "s";
        }

        return `${value} ${translation}`;
    }
}
