import { Field, Form, Formik, FormikProps } from "formik";
import { observer } from "mobx-react-lite";
import React from "react";

import DatePickerComponent from "components/commons/Formik/DatePickerInput/DatePickerInput";
import { Employee } from "models/Employee/Employee";
import AppStore from "stores/AppStore";
import EmployeeDataStore from "stores/EmployeeManagementStore";

import "./EmploymentStartDateForm.scss";

const DataForm: React.FC<Employee & FormikProps<Employee>> = ({ submitForm, isValid }) => {
    const handleChange = () => {
        if (isValid) {
            submitForm();
        }
    };

    const {
        i18n: {
            employees: { employmentStartDate }
        }
    } = AppStore;

    return (
        <Form>
            <Field name="employmentStartDate" label={employmentStartDate} component={DatePickerComponent} showYearDropdown minDate={new Date("2015-01-01")} onChange={handleChange} />
        </Form>
    );
};

const EmploymentStartDateForm = () => {
    const {
        employee: { employmentStartDate }
    } = EmployeeDataStore;

    const mapValues = (values: Employee) => ({
        employment_start_date: values.employmentStartDate
    });

    const handleSubmit = (values) => {
        EmployeeDataStore.putEmployeeData(mapValues(values) as any);
    };

    return (
        <div className="c-employment-start-date-form">
            <Formik initialValues={{ employmentStartDate }} onSubmit={handleSubmit} component={DataForm} />
        </div>
    );
};

export default observer(EmploymentStartDateForm);
