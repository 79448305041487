import { InvoiceModifications as InvoiceModificationsModel } from "models/Invoice/InvoiceModifications";

import Controller from "../Controller";

class InvoiceModifications extends Controller {
    public getInvoiceModifications = async (userId: string): Promise<InvoiceModificationsModel> => {
        const data = await this.get(`/employees/${userId}/invoice_modifications`);

        return this.parse(InvoiceModificationsModel, data);
    };

    public addInvoiceModification = async (userId: string, body: any): Promise<InvoiceModificationsModel> => {
        const data = await this.post(`/employees/${userId}/invoice_modifications`, body);

        return this.parse(InvoiceModificationsModel, data);
    };

    public deleteInvoiceModifications = async (userId: string, id: string): Promise<InvoiceModificationsModel> => {
        const data = await this.delete(`/employees/${userId}/invoice_modifications/${id}`);

        return this.parse(InvoiceModificationsModel, data);
    };
}

const invoice = new InvoiceModifications("");

export default invoice;
