import { Field, Form, Formik, FormikProps } from "formik";
import { observer } from "mobx-react-lite";
import React from "react";

import SelectInput from "components/commons/Formik/SelectInput/SelectInput";
import Page from "components/commons/Page/Page";
import Row from "components/commons/Row/Row";
import AppStore from "stores/AppStore";
import EmployeeDataStore from "stores/EmployeeManagementStore";
import Button from "components/commons/Button/Button";
import { Props } from "./AddBenefitFormInterface";

import "./AddBenefitForm.scss";

const BenefitForm: React.FC<FormikProps<{ benefit: string; package: string }>> = (props) => {
    const { submitForm, handleReset, values } = props;

    const { availableBenefits } = EmployeeDataStore;

    const { benefit, package: packageId } = values;

    const {
        i18n: {
            employees: { chooseBenefit, choosePackage, cancel, save }
        }
    } = AppStore;

    let packages = [];

    if (benefit) {
        const filteredPackages = availableBenefits.filter((availableBenefit) => availableBenefit.family === benefit);
        packages = filteredPackages.map(({ id, packageName }) => ({ text: packageName, value: id }));
    }

    const getFamilies = () => {
        const arrayOfFamilies = availableBenefits.map((availableBenefit) => availableBenefit.family);
        return [...new Set(arrayOfFamilies)];
    };

    const uniqueFamilies = getFamilies();
    const families = uniqueFamilies.map((family) => ({ text: family, value: family }));

    return (
        <Form>
            <Row>
                <Page className="c-add-benefit-form__page-first">
                    <Field name="benefit" placeholder={chooseBenefit} component={SelectInput} options={families} />
                </Page>
                <Page className="c-add-benefit-form__page-second">
                    <Field name="package" placeholder={choosePackage} component={SelectInput} disabled={!packages.length} options={packages} />
                </Page>
            </Row>
            <Row>
                <Page className="c-add-benefit-form__page-first">
                    <Button text={cancel} cancel onClick={handleReset} />
                </Page>
                <Page className="c-add-benefit-form__page-second">
                    <Button disabled={!packageId} text={save} onClick={submitForm} />
                </Page>
            </Row>
        </Form>
    );
};

const AddBenefitForm: React.FC<Props> = ({ onCancel, onSubmit }) => {
    const handleSubmit = async (values, {}) => {
        await EmployeeDataStore.addBenefit(Number(values.package));

        onSubmit();
    };

    const handleReset = () => {
        onCancel();
    };

    return (
        <div className="c-add-benefit-form">
            <Formik
                initialValues={{
                    benefit: "",
                    package: ""
                }}
                onReset={handleReset}
                onSubmit={handleSubmit}
                component={BenefitForm}
            />
        </div>
    );
};

export default observer(AddBenefitForm);
