import { observer } from "mobx-react-lite";
import React from "react";

import { groupBy } from "helpers/functions";
import { Charge } from "models/Invoice/Charge";
import AppStore from "stores/AppStore";
import EmployeeDataStore from "stores/EmployeeManagementStore";
import BenefitsRow from "../BenefitsRow/BenefitsRow";

import "./BenefitsTable.scss";

const BenefitsTable = () => {
    const {
        i18n: {
            invoiceDetails: { benefit, package: packageTranslate }
        }
    } = AppStore;

    const {
        benefitsCharges: { benefits }
    } = EmployeeDataStore;

    const renderRows = (charges: Charge[], key: string) => charges.map((charge, index) => <BenefitsRow family={key} key={charge.packageName} index={index} charge={charge} />);

    const renderBody = () => {
        const groupedBenefits = groupBy(benefits, "family");
        const elements = [];

        Object.keys(groupedBenefits).map((key) => {
            const charges = groupedBenefits[key];

            elements.push(renderRows(charges, key));
            elements.push(
                <tr className="c-benefits-table__divider" key={key}>
                    <td className="c-benefits-table__divider-td" />
                </tr>
            );
            return true;
        });

        return elements;
    };

    return (
        <table className="c-benefits-table">
            <thead>
                <tr>
                    <th className="c-benefits-table__th u-align-left">{benefit}</th>
                    <th className="c-benefits-table__th u-align-left">{packageTranslate}</th>
                    <th className="c-benefits-table__th" />
                </tr>
                <tr className="c-benefits-table__divider">
                    <td className="c-benefits-table__divider-td" />
                </tr>
            </thead>
            <tbody>{renderBody()}</tbody>
        </table>
    );
};

export default observer(BenefitsTable);
