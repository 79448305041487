import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";

import "./ModificationsManagement.scss";

import Loader from "components/commons/Loader/Loader";
import AppStore from "stores/AppStore";
import EmployeeDataStore from "stores/EmployeeManagementStore";
import AddModification from "./components/AddModification/AddModification";
import ModificationsTable from "./components/ModificationsTable/ModificationsTable";

const ModificationsManagement = () => {
    const { invoiceModifications } = EmployeeDataStore;

    const {
        i18n: {
            invoiceDetails: { extraChargesAndSupplements },
            employees: { nextInvoiceModifications, noExtraModifications }
        }
    } = AppStore;

    useEffect(() => {
        EmployeeDataStore.getEmployeeInvoiceModifications();
    }, []);

    if (!invoiceModifications) {
        return <Loader />;
    }

    const hasModifications = !!invoiceModifications.invoiceModifications.length;

    const renderHeader = () => (
        <div className="c-modifications__header">
            <h3 className="c-modifications__h3">{extraChargesAndSupplements}</h3>
            <p className="c-modifications__p">{nextInvoiceModifications}</p>
        </div>
    );

    const renderNoExtraModifications = () => <p className="c-modifications__no-modifications">{noExtraModifications}</p>;

    return (
        <>
            <div className="c-modifications">
                {renderHeader()}
                {!hasModifications && renderNoExtraModifications()}
                {hasModifications && <ModificationsTable />}
                <AddModification />
            </div>
        </>
    );
};

export default observer(ModificationsManagement);
