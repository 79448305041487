import { faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import moment from "moment";
import React, { useState } from "react";

import AppStore from "stores/AppStore";

import { Props } from "./MonthModalInterface";

import "./MonthModal.scss";

const MonthModal: React.FC<Props> = ({ currentDate, minDate, refProp, onChange }) => {
    const [date, setDate] = useState(currentDate);

    const {
        i18n: {
            invoicePreview: { months }
        }
    } = AppStore;

    const getYear = () => moment(date).year();

    const renderYearPicker = () => {
        const previousYearArrow = () => {
            const isDisabled = getYear() <= 2020;

            const className = classNames("c-month-modal__year-arrow", {
                "c-month-modal__year-arrow--disabled": isDisabled
            });

            return (
                <div
                    className={className}
                    onClick={() => {
                        if (!isDisabled) {
                            const newDate = moment(date).subtract(1, "year").toDate();
                            setDate(newDate);
                        }
                    }}
                >
                    <FontAwesomeIcon icon={faChevronLeft} />
                </div>
            );
        };

        const nextYearArrow = () => (
            <div
                className="c-month-modal__year-arrow"
                onClick={() => {
                    const newDate = moment(date).add(1, "year").toDate();
                    setDate(newDate);
                }}
            >
                <FontAwesomeIcon icon={faChevronRight} />
            </div>
        );

        return (
            <div className="c-month-modal__year-picker">
                {previousYearArrow()}
                <div className="c-month-modal__year-label">{getYear()}</div>
                {nextYearArrow()}
            </div>
        );
    };

    const renderMonths = () => {
        const momentDate = moment(date);

        const minimalMomentDate = moment(minDate).day(0);
        const maximalMomentDate = moment();

        return (
            <div className="c-month-modal__months-wrapper">
                {Object.keys(months).map((key) => {
                    const currentMonth = moment()
                        .year(getYear())
                        .month(Number(key) - 1)
                        .day(1);

                    const isDisabled = currentMonth.isBefore(minimalMomentDate) || currentMonth.isAfter(maximalMomentDate);

                    const className = classNames("c-month-modal__month", {
                        "c-month-modal__month--active": momentDate.month() + 1 === Number(key),
                        "c-month-modal__month--disabled": isDisabled
                    });

                    return (
                        <div
                            key={key}
                            className={className}
                            onClick={() => {
                                if (!isDisabled) {
                                    onChange(currentMonth.toDate());
                                }
                            }}
                        >
                            {months[key]}
                        </div>
                    );
                })}
            </div>
        );
    };

    return (
        <div className="c-month-modal" ref={refProp}>
            {renderYearPicker()}
            {renderMonths()}
        </div>
    );
};

export default MonthModal;
