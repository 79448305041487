import { observer } from "mobx-react-lite";
import React, { useRef, useState } from "react";

import "./UploadInvoiceModal.scss";

import Modal from "components/commons/Modal/Modal";
import { getFormattedMonth, getFormattedYear } from "helpers/functions";
import AppStore from "stores/AppStore";
import InvoiceStore from "stores/InvoiceStore";
import { Props } from "./UploadInvoiceModalInterface";

const UploadInvoiceModal: React.FC<Props> = ({ isModalVisible, onHideModal }) => {
    const {
        i18n: {
            invoicePreview: { uploadInvoice, uploadFromDisk, beforeContinuing, afterGenerating, selectOnlyOneFile, thisTypeIsNotSupported }
        }
    } = AppStore;

    const { activeMonth, uploadInvoiceForActiveMonth } = InvoiceStore;

    const fileInput = useRef(null);
    const [errorMessage, setErrorMessage] = useState(undefined);

    const validateFileInput = () => {
        const input = fileInput.current;

        if (!input.files.length) {
            setErrorMessage(selectOnlyOneFile);
            return false;
        }

        const file = input.files[0];

        if (file.type !== "application/pdf") {
            setErrorMessage(thisTypeIsNotSupported);
            return false;
        }

        return true;
    };

    const uploadFile = async () => {
        const input = fileInput.current;
        const file = input.files[0];
        await uploadInvoiceForActiveMonth(file);
    };

    const handleFileInputChange = async () => {
        if (validateFileInput()) {
            uploadFile();
        }
    };

    return (
        <div className="c-upload-invoice-modal">
            <Modal
                isModalVisible={isModalVisible}
                title={uploadInvoice}
                acceptText={uploadFromDisk}
                onAccept={() => {
                    const input = fileInput.current;
                    input.click();
                }}
                onClose={() => {
                    onHideModal();
                }}
            >
                <input className="c-upload-invoice-modal__file-input" ref={fileInput} type="file" onChange={handleFileInputChange} />
                <div className="c-upload-invoice-modal__content">
                    <p className="u-bold">
                        {beforeContinuing} {getFormattedMonth(activeMonth)} {getFormattedYear(activeMonth)}.
                    </p>
                    <p>{afterGenerating}</p>
                    {errorMessage && <p className="c-upload-invoice-modal__error">{errorMessage}</p>}
                </div>
            </Modal>
        </div>
    );
};

export default observer(UploadInvoiceModal);
