/* eslint-disable no-unused-vars */
import { SummaryAmounts } from "models/InvoiceStatuses/SummaryAmounts";
import { Field } from "sparkson";
import { Marker } from "../Marker";

@Marker
export class Summary {
    constructor(
        @Field("issued") public issued: SummaryAmounts,
        @Field("missing") public missing: SummaryAmounts,
        @Field("total") public total: SummaryAmounts
    ) { }
}
