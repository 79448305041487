/* eslint-disable no-unused-vars */
import { Money } from "models/Invoice/Money";
import { ArrayField, Field } from "sparkson";
import { Marker } from "../Marker";
import { InvoiceModification } from "./InvoiceModification";

@Marker
export class InvoiceModifications {
    constructor(
        @ArrayField("invoice_modifications", InvoiceModification) public invoiceModifications: InvoiceModification[],
        @Field("supplements_amount", true, null, new Money(0)) public supplementsAmount: Money,
        @Field("charges_amount", true, null, new Money(0)) public chargesAmount: Money,
        @Field("total_amount", true, null, new Money(0)) public totalAmount: Money
    ) {}
}
