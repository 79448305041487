import { observer } from "mobx-react-lite";
import React from "react";

import { Props } from "./SearchInterface";

import "./Search.scss";

const Search: React.FC<Props> = ({ searchString, onChange, placeholder }) => (
    <div className="c-search">
        <input
            className="c-search__input"
            placeholder={placeholder}
            type="text"
            value={searchString || ""}
            onChange={(event) => {
                onChange(event);
            }}
        />
    </div>
);

export default observer(Search);
