import { Registrable } from "sparkson";

@Registrable
export class Money {
    public value: number;

    constructor(value: number = 0) {
        this.value = value;
    }

    public toString() {
        const stringValue = (this.value / 100).toFixed(2);
        const values = stringValue.split(".");
        let integers = values[0];
        const decimals = values[1];
        const rgx = /(\d+)(\d{3})/;

        while (rgx.test(integers)) {
            integers = integers.replace(rgx, "$1 $2");
        }

        return `${integers},${decimals}`;
    }
}
