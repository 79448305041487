import React, { useState } from "react";

import Button from "components/commons/Button/Button";
import { observer } from "mobx-react-lite";
import AppStore from "stores/AppStore";
import AddBenefitForm from "../AddBenefitForm/AddBenefitForm";
import "./AddBenefit.scss";

const AddBenefit = () => {
    const {
        i18n: {
            employees: { addBenefit }
        }
    } = AppStore;

    const [isAdding, setIsAdding] = useState(false);

    const renderAddButton = () => (
        <div className="c-add-benefit__button">
            <Button
                text={addBenefit}
                onClick={() => {
                    setIsAdding(true);
                }}
            />
        </div>
    );

    const renderAddForm = () => (
        <div className="c-add-benefit__form">
            <AddBenefitForm
                onCancel={() => {
                    setIsAdding(false);
                }}
                onSubmit={() => {
                    setIsAdding(false);
                }}
            />
        </div>
    );

    return (
        <div className="c-add-benefit">
            {!isAdding && renderAddButton()}
            {isAdding && renderAddForm()}
        </div>
    );
};

export default observer(AddBenefit);
