import { faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import { observer } from "mobx-react-lite";
import moment, { Moment } from "moment";
import React from "react";

import { getFormattedMonth, getFormattedYear } from "helpers/functions";
import { useComponentVisible } from "helpers/hooks/useComponentVisible";
import MonthModal from "./components/MonthModal/MonthModal";
import { Props } from "./MonthPickerInterface";

import "./MonthPicker.scss";

const MonthPicker: React.FC<Props> = ({ activeMonth, onChange, minDate }) => {
    const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);

    const isBeforeMinDate = (month: Moment) => month.isBefore(moment(minDate));

    const renderPreviousMonthArrow = () => {
        const month = moment(activeMonth).subtract(1, "months");
        const isDisabled = isBeforeMinDate(month);

        const previousArrowClassName = classNames("c-month-picker__previous-month-arrow", {
            "c-month-picker__arrow-disabled": isDisabled
        });

        return (
            <div
                className={previousArrowClassName}
                onClick={() => {
                    if (!isDisabled) {
                        onChange(month.toDate());
                    }
                }}
            >
                <FontAwesomeIcon icon={faChevronLeft} />
            </div>
        );
    };

    const renderNextMonthArrow = () => {
        const month = moment(activeMonth).add(1, "months");
        const isDisabled = month.isAfter(moment());

        const nextArrowClassName = classNames("c-month-picker__previous-month-arrow", {
            "c-month-picker__arrow-disabled": isDisabled
        });

        return (
            <div
                className={nextArrowClassName}
                onClick={() => {
                    if (!isDisabled) {
                        onChange(month.toDate());
                    }
                }}
            >
                <FontAwesomeIcon icon={faChevronRight} />
            </div>
        );
    };

    const renderMonthLabel = () => (
        <div
            className="c-month-picker__month-label"
            onClick={() => {
                setIsComponentVisible(true);
            }}
        >
            {getFormattedMonth(activeMonth)} {getFormattedYear(activeMonth)}
            {isComponentVisible && (
                <MonthModal
                    refProp={ref}
                    currentDate={activeMonth}
                    minDate={minDate}
                    onChange={(date) => {
                        onChange(date);
                    }}
                />
            )}
        </div>
    );

    return (
        <div className="c-month-picker">
            {renderPreviousMonthArrow()}
            {renderMonthLabel()}
            {renderNextMonthArrow()}
        </div>
    );
};

export default observer(MonthPicker);
