import { observer } from "mobx-react-lite";
import React from "react";

import "./Header.scss";

import logo from "assets/images/logo.svg";
import Settings from "components/Header/components/Settings/Settings";
import AppStore from "stores/AppStore";
import Menu from "./components/Menu/Menu";
import User from "./components/User/User";

const Header = () => {
    const { isAdmin } = AppStore;

    return (
        <div className="c-header">
            <div className="c-header__logo">
                <img className="c-header__logo-img" src={logo} alt="sparkbill" />
            </div>

            <div className="c-header__right-items">
                {isAdmin && <Menu />}
                <Settings />
                <User />
            </div>
        </div>
    );
};

export default observer(Header);
