import { observer } from "mobx-react-lite";
import React, { useState } from "react";

import Button from "components/commons/Button/Button";
import Modal from "components/commons/Modal/Modal";
import Switch from "components/commons/Switch/Switch";
import TextAreaInput from "components/commons/TextAreaInput/TextAreaInput";

import AppStore from "stores/AppStore";

import "./SettingsModal.scss";

const SettingsModal: React.FC = () => {
    const {
        isSettingsModalVisible,
        setIsSettingsModalVisible,
        generateInvoicesAutomatically: generateInvoicesAutomaticallyValue,
        hideSensitiveData: hideSensitiveDataValue,
        invoiceFootnote: invoiceFootnoteValue,
        i18n: {
            commons: { settings, done },
            invoicePreview: {
                generateInvoicesAutomatically: generateInvoicesAutomaticallyTranslate,
                theInvoiceWillBeIssued, forImprovedPrivacy,
                hideSensitiveData: hideSensitiveDataTranslate,
                invoiceFooter: invoiceFootnoteTranslate,
                optionalTextAtTheBottom: optionalTextAtTheBottomTranslate
            }
        },
        setSettings
    } = AppStore;

    const [hideSensitiveData, setHideSensitiveData] = useState(hideSensitiveDataValue);
    const [generateInvoicesAutomatically, setGenerateInvoicesAutomatically] = useState(generateInvoicesAutomaticallyValue);
    const [invoiceFootnote, setInvoiceFootnote] = useState(invoiceFootnoteValue);

    const ModalHeader = () => (
        <div className="c-settings-modal__header">
            <h2>{settings}</h2>
            <Button
                className="c-settings-modal__done-button"
                text={done}
                onClick={async () => {
                    await setSettings({
                        autoGeneration: generateInvoicesAutomatically,
                        hideSensitiveData,
                        invoiceFootnote
                    });
                    setIsSettingsModalVisible(false);
                }}
            />
        </div>
    );

    return (
        <div className="c-settings-modal">
            <Modal
                isModalVisible={isSettingsModalVisible}
                disableFooter
                customHeader={<ModalHeader />}
                onClose={() => {
                    setIsSettingsModalVisible(false);
                }}
            >
                <div className="c-settings-modal__content">
                    <div className="c-settings-modal__option">
                        <Switch
                            label={generateInvoicesAutomaticallyTranslate}
                            description={theInvoiceWillBeIssued}
                            checked={generateInvoicesAutomatically}
                            value={generateInvoicesAutomatically}
                            onClick={() => {
                                setGenerateInvoicesAutomatically(!generateInvoicesAutomatically);
                            }}
                        />
                    </div>
                    <div className="c-settings-modal__option">
                        <Switch
                            label={hideSensitiveDataTranslate}
                            description={forImprovedPrivacy}
                            checked={hideSensitiveData}
                            value={hideSensitiveData}
                            onClick={() => {
                                setHideSensitiveData(!hideSensitiveData);
                            }}
                        />
                    </div>
                    <div className="c-settings-modal__option">
                        <TextAreaInput
                            label={invoiceFootnoteTranslate}
                            description={optionalTextAtTheBottomTranslate}
                            value={invoiceFootnote}
                            onChange={setInvoiceFootnote}
                        />
                    </div>
                </div>
            </Modal>
        </div>
    );
};

export default observer(SettingsModal);
