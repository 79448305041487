/* eslint-disable no-unused-vars */
import { Field } from "sparkson";

import { Marker } from "../Marker";
import { Money } from "./Money";

@Marker
export class InvoiceModificationDetails {
    constructor(
    @Field("description", true) public description: string,
    @Field("transfer_date", true) public transferDate: string,
    @Field("destination_country", true) public destinationCountry: string,
    @Field("amount", true, null, new Money(0)) public amount: Money
    ) { }
}
