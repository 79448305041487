/* eslint-disable no-unused-vars */
import { ArrayField, Field } from "sparkson";
import { Marker } from "../Marker";
import { InvoiceStatus } from "./InvoiceStatus";
import { Summary } from "./Summary";

@Marker
export class InvoiceStatuses {
    constructor(
        @ArrayField("employee_statuses", InvoiceStatus) public invoiceStatuses: InvoiceStatus[],
        @Field("summary") public summary: Summary,
        @Field("month") public month: string
    ) { }
}
