import { observer } from "mobx-react-lite";
import React from "react";

import ButtonInline from "components/commons/ButtonInline/ButtonInline";
import Info from "components/commons/Info/Info";
import AppStore from "stores/AppStore";

import "./GenerateInvoicesAutomatically.scss";

const GenerateInvoicesAutomatically = () => {
    const {
        i18n: {
            invoicePreview: { timepotFor, theInvoiceWillBeIssued },
            commons: { changeSettings }
        },
        generateInvoicesAutomatically,
        setIsSettingsModalVisible
    } = AppStore;

    const infoText = (
        <>
            {`${theInvoiceWillBeIssued} ${timepotFor} `}
            <ButtonInline
                onClick={() => {
                    setIsSettingsModalVisible(true);
                }}
                text={changeSettings}
            />
        </>
    );

    const renderInfo = () => <Info text={infoText} />;
    return <div className="c-generate-invoices-automatically">{generateInvoicesAutomatically && renderInfo()}</div>;
};

export default observer(GenerateInvoicesAutomatically);
