import { RouteComponentProps, withRouter } from "react-router-dom";
import React from "react";

import { nth } from "helpers/functions";
import AppStore from "stores/AppStore";

import { Props } from "./EmployeesTableRowsInterface";
import "./EmployeesTableRows.scss";

const EmployeesTableRows: React.FC<Props & RouteComponentProps> = ({
    employees, header, history
}) => {
    const renderTenure = (tenure) => (
        <div className="c-employees-table-tbody__tenure">
            ({tenure.toString()})
        </div>
    );

    const renderDate = (date) => {
        const {
            i18n: {
                invoicePreview: { months }
            }
        } = AppStore;
        const startDate = new Date(date);
        const day = startDate.getDate();
        const month = startDate.getMonth() + 1;
        const year = startDate.getFullYear();

        return (
            <div className="c-employees-table-tbody__date">
                <span className="c-employees-table-tbody__date-day">
                    {day}
                    <span className="c-employees-table-tbody__date-nth">{nth(day)} </span>
                </span>
                {months[month]} {year}
            </div>
        );
    };

    const renderStartDate = (employmentStartDate, tenure) => (
        <div className="c-employees-table-tbody__start-date">
            {renderDate(employmentStartDate)}
            {renderTenure(tenure)}
        </div>
    );

    const renderHeader = () => {
        if (!header) {
            return <></>;
        }
        return (
            <thead>
                <tr className="c-employees-table-tbody__header">
                    <td className="c-employees-table-tbody__header-td" colSpan={3}>
                        {header} ({employees.length})
                    </td>
                </tr>
            </thead>
        );
    };

    const renderRows = () => employees.map(({ id, name, employmentStartDate, tenure }) => (
        <tr
            className="c-employees-table-tbody__tr"
            key={id}
            onClick={() => {
                history.push(`employee/${id}`);
            }}
        >
            <td className="c-employees-table-tbody__td u-align-left">{name}</td>
            <td className="c-employees-table-tbody__td u-align-left">{id}</td>
            <td className="c-employees-table-tbody__td u-align-left">
                {renderStartDate(employmentStartDate, tenure)}
            </td>
        </tr>
    ));

    return (
        <>
            {renderHeader()}
            <tbody className="c-employees-table-tbody">{renderRows()}</tbody>
        </>
    );
};

export default withRouter(EmployeesTableRows);
