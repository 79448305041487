import { observer } from "mobx-react-lite";
import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import "reflect-metadata";
import { registerNumberMapper, registerStringMapper } from "sparkson";

import "./App.scss";

import Error from "components/commons/Error/Error";
import RouteContent from "components/commons/RouteContent/RouteContent";
import Header from "components/Header/Header";
import { Tenure } from "models/Employee/Tenure";
import { HourlyRate } from "models/Invoice/HourlyRate";
import { Money } from "models/Invoice/Money";
import EmployeeInvoiceRoute from "routes/EmployeeInvoice/EmployeeInvoice";
import EmployeeListRoute from "routes/EmployeeList/EmployeeList";
import EmployeeManagementRoute from "routes/EmployeeManagement/EmployeeManagement";
import InvoicesListRoute from "routes/InvoicesList/InvoicesList";
import Login from "routes/Login/Login";
import { UserModel } from "models/User";
import { Credential } from "models/Credential";
import jwt_decode from "jwt-decode";
import { sparkbill_oauth } from "conf/LocalStorage";
import InvoiceRoute from "./routes/Invoice/Invoice";
import AppStore from "./stores/AppStore";

registerNumberMapper(Money, (val: number) => new Money(val));
registerNumberMapper(HourlyRate, (val: number) => new HourlyRate(val));
registerStringMapper(Tenure, (val) => new Tenure(val));

const App: React.FC = observer(() => {
    AppStore.startApplication();
    const credential = localStorage.getItem(sparkbill_oauth);
    if (credential != null) {
        const cred: Credential = jwt_decode(credential);
        if (cred.exp > Date.now() / 1000) {
            AppStore.setUserStatus("logged");

            const decoded: UserModel = jwt_decode(credential);
            AppStore.setUser(decoded.email, decoded.picture);
        }
    }
    const { error, userStatus } = AppStore;

    if (error) {
        return <Error />;
    }

    if (!userStatus || userStatus === "loggedOut") {
        return <Login />;
    }

    return (
        <RouteContent>
            <Header />

            <Switch>
                <Route path="/" exact>
                    <Redirect to="/invoice" />
                </Route>

                <Route path="/invoice" exact>
                    <InvoiceRoute />
                </Route>

                <Route path="/invoices" exact>
                    <InvoicesListRoute />
                </Route>

                <Route path="/invoices/:id/:month" exact>
                    <EmployeeInvoiceRoute />
                </Route>

                <Route path="/employee" exact>
                    <EmployeeListRoute />
                </Route>

                <Route path="/employee/:id" exact>
                    <EmployeeManagementRoute />
                </Route>
            </Switch>
        </RouteContent>
    );
});

export default App;
