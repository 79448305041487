import { Registrable } from "sparkson";

@Registrable
export class HourlyRate {
    public value: number;

    constructor(value: number = 0) {
        this.value = value;
    }

    public toString() {
        return this.value / 100;
    }
}
