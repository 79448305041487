import { observer } from "mobx-react-lite";
import { useState } from "react";

import "./InvoiceSummary.scss";

import Button from "components/commons/Button/Button";
import ButtonInline from "components/commons/ButtonInline/ButtonInline";
import Info from "components/commons/Info/Info";
import AppStore from "stores/AppStore";
import InvoiceStore from "stores/InvoiceStore";
import GenerateInvoiceModal from "./components/GenerateInvoiceModal/GenerateInvoiceModal";
import UploadInvoiceModal from "./components/UploadInvoiceModal/UploadInvoiceModal";

const InvoiceSummary = () => {
    const [isGenerateModalVisible, setIsGenerateModalVisible] = useState(false);
    const [isUploadModalVisible, setIsUploadModalVisible] = useState(false);
    const {
        invoice: {
            invoiceData: {
                amounts: { gross }
            }
        }
    } = InvoiceStore;

    const {
        i18n: {
            invoicePreview: { generateInvoice, uploadFromDisk },
            commons: { youCanEnableAutomaticInvoiceIssuing, changeSettings }
        },
        generateInvoicesAutomatically,
        setIsSettingsModalVisible
    } = AppStore;

    const handleGenerateInvoiceClick = () => {
        setIsGenerateModalVisible(true);
    };

    const handleUploadFromDiskClick = () => {
        setIsUploadModalVisible(true);
    };

    const renderButtons = () => {
        const infoText = (
            <>
                {youCanEnableAutomaticInvoiceIssuing}
                <ButtonInline
                    text={changeSettings}
                    onClick={() => {
                        setIsSettingsModalVisible(true);
                    }}
                />
            </>
        );

        return (
            <>
                <div className="c-invoice-summary__buttons">
                    <Button text={generateInvoice} className="c-invoice-summary__button" onClick={handleGenerateInvoiceClick} />
                    <Button text={uploadFromDisk} className="c-invoice-summary__button" onClick={handleUploadFromDiskClick} />
                </div>
                <div className="c-invoice-summary__info">
                    <Info gray text={infoText} />
                </div>
            </>
        );
    };

    return (
        <div className="c-invoice-summary">
            {!generateInvoicesAutomatically && !!gross.value && renderButtons()}

            <GenerateInvoiceModal
                isModalVisible={isGenerateModalVisible}
                onHideModal={() => {
                    setIsGenerateModalVisible(false);
                }}
            />
            <UploadInvoiceModal
                isModalVisible={isUploadModalVisible}
                onHideModal={() => {
                    setIsUploadModalVisible(false);
                }}
            />
        </div>
    );
};

export default observer(InvoiceSummary);
