import { I18nResolver } from "i18n-ts";
import { en } from "i18n/en";
import { i18n } from "i18n/i18n";
import { action, makeObservable, observable } from "mobx";
import { Settings as SettingsModel } from "models/Settings";

import Profile from "api/controllers/Profile";
import Settings from "api/controllers/Settings";
import { Profile as ProfileModel } from "models/Profile";
import type { UserStatus } from "models/UserStatus";
import { UserModel } from "models/User";

class Store {
    @observable public isActive: boolean = false;
    @observable public isSettingsModalVisible: boolean = false;
    @observable public generateInvoicesAutomatically: boolean = false;
    @observable public hideSensitiveData: boolean = false;
    @observable public isFaderVisible: boolean = false;
    @observable public invoiceFootnote: string;
    @observable public i18n: typeof en = undefined;
    @observable public isAdmin: boolean = false;
    @observable public isManager: boolean = false;
    @observable public id: string;
    @observable public employmentStartDate: string;
    @observable public user: UserModel;
    @observable public userStatus?: UserStatus = undefined;
    @observable public error: any;

    constructor() {
        makeObservable(this);
    }

    private language: string = "en";

    @action public startApplication = () => {
        this.isActive = true;
        this.setLanguage("en");
    };

    @action public setIsSettingsModalVisible = (isSettingsModalVisible: boolean) => {
        this.isSettingsModalVisible = isSettingsModalVisible;
    };

    @action public showFader = () => {
        this.isFaderVisible = true;
    };

    @action public hideFader = () => {
        this.isFaderVisible = false;
    };

    @action public getProfile = async () => {
        const profile = await Profile.getProfile();

        if (profile) {
            this.setProfileValues(profile);
        }
    };

    @action public setError = (error) => {
        this.error = error;
    };

    @action public setLanguage = (language) => {
        this.language = language;
        this.i18n = new I18nResolver(i18n, language).translation;
    };

    @action public setSettings = async (settings: SettingsModel) => {
        this.showFader();

        const data = await Settings.setSettings({
            auto_generation: settings.autoGeneration,
            hide_sensitive_data: settings.hideSensitiveData,
            invoice_footnote: settings.invoiceFootnote
        });

        this.generateInvoicesAutomatically = data.autoGeneration;
        this.hideSensitiveData = data.hideSensitiveData;
        this.invoiceFootnote = data.invoiceFootnote;
        this.hideFader();
    };

    @action public setUser = async (email: string, picture: string) => {
        this.user = new UserModel(email, picture);
        await this.getProfile();
        this.setUserStatus("logged");
    };

    @action public setHideSensitiveData = (hideSensitiveData: boolean) => {
        this.hideSensitiveData = hideSensitiveData;
    };

    @action public setIsAdmin = (isAdmin: boolean) => {
        this.isAdmin = isAdmin;
    };

    @action public setIsManager = (isManager: boolean) => {
        this.isManager = isManager;
    };

    @action public setUserStatus = (status?: UserStatus) => {
        this.userStatus = status;
    };

    @action private setProfileValues = (profile: ProfileModel) => {
        this.isAdmin = profile.isAdmin;
        this.isManager = profile.isManager;
        this.generateInvoicesAutomatically = profile.settings.autoGeneration;
        this.hideSensitiveData = profile.settings.hideSensitiveData;
        this.invoiceFootnote = profile.settings.invoiceFootnote;
        this.id = profile.id;
        this.employmentStartDate = profile.employmentStartDate;
    };
}

const AppStore = new Store();

export default AppStore;
