import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { observer } from "mobx-react-lite";
import React, { useRef } from "react";
import { createPortal } from "react-dom";

import "./Modal.scss";

import AppStore from "stores/AppStore";
import Button from "../Button/Button";
import { Props } from "./ModalInterface";

const modalRoot = document.getElementById("modal");

const Modal: React.FC<Props> = ({ onClose, onAccept, disableFooter, customHeader, title, isModalVisible, children, acceptText }) => {
    const {
        i18n: {
            employees: { cancel }
        }
    } = AppStore;

    const modal = useRef(null);

    if (!isModalVisible) {
        return null;
    }

    const handleClickOutside = (event) => {
        if (event.target === modal.current) {
            onClose();
        }
    };

    const renderHeader = () => {
        if (customHeader) {
            return customHeader;
        }

        return (
            <div className="c-modal__header">
                <h3 className="c-modal__header-h3">{title}</h3>
                <div className="c-modal__header-close" onClick={onClose}>
                    <FontAwesomeIcon icon={faTimes} />
                </div>
            </div>
        );
    };

    const renderFooter = () => {
        if (!disableFooter) {
            return (
                <div className="c-modal__footer">
                    <Button className="c-modal__footer-button" cancel onClick={onClose} text={cancel} />
                    <Button className="c-modal__footer-button c-modal__accept-button" onClick={onAccept} text={acceptText} />
                </div>
            );
        }
    };

    return createPortal(
        <div className="c-modal" ref={modal} onClick={handleClickOutside}>
            <div className="c-modal__content">
                {renderHeader()}

                <div className="c-modal__children">{children}</div>

                {renderFooter()}
            </div>
        </div>,
        modalRoot
    );
};

export default observer(Modal);
