/* eslint-disable no-unused-vars */
import { Field } from "sparkson";
import { Marker } from "../Marker";
import { Company } from "../Invoice/Company";

@Marker
export class EmployeeData {
    constructor(
        @Field("name") public name: string,
        @Field("employment_start_date", true) public employmentStartDate: string,
        @Field("is_invoicing", true) public isInvoicing: boolean,
        @Field("company_data") public companyData: Company
    ) { }
}
