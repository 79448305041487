import { observer } from "mobx-react-lite";
import React from "react";

import InvoiceStore from "stores/InvoiceStore";
import Companies from "./components/Companies/Companies";
import Dates from "./components/Dates/Dates";
import GenerateInvoicesAutomatically from "./components/GenerateInvoicesAutomatically/GenerateInvoicesAutomatically";
import InvoiceState from "./components/InvoiceState/InvoiceState";
import InvoiceSummary from "./components/InvoiceSummary/InvoiceSummary";

import "./InvoicePreview.scss";

const InvoicePreview = () => {
    const {
        invoice: {
            invoiceData: { status }
        }
    } = InvoiceStore;

    return (
        <div className="c-invoice-preview">
            <Companies />
            <Dates />
            {status === "missing" && <InvoiceSummary />}
            {status !== "missing" && <InvoiceState />}
            <GenerateInvoicesAutomatically />
        </div>
    );
};

export default observer(InvoicePreview);
