/* eslint-disable no-unused-vars */
import { Amounts } from "models/Invoice/Amounts";
import { Field } from "sparkson";
import { Marker } from "../Marker";

@Marker
export class SummaryAmounts {
    constructor(
        @Field("count") public count: number,
        @Field("amounts") public amounts: Amounts
    ) {}
}
