import { FieldProps } from "formik";
import React from "react";

import { Props } from "./CheckboxInputInterface";

import "./CheckboxInput.scss";

const CheckboxInput: React.FC<FieldProps & Props> = ({ field, form: { setFieldValue }, onChange, label }: any) => {
    const { name, value } = field;

    return (
        <div
            className="c-checkbox-input"
            onClick={() => {
                setFieldValue(name, !value);

                if (onChange) {
                    onChange();
                }
            }}
        >
            <input className="c-checkbox-input__input" type="checkbox" {...field} checked={value} />
            <label className="c-checkbox-input__label" htmlFor={name}>
                {label}
            </label>
        </div>
    );
};

export default CheckboxInput;
