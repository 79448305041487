import classNames from "classnames";
import React from "react";

import { Props } from "./CellInterface";

import "./Cell.scss";

const Cell: React.FC<Props> = ({ children, big, bold, supplement, charge, alignToLeft, className: classNameProps }) => {
    const className = classNames("c-cell", {
        "c-cell--align-to-left": alignToLeft,
        "c-cell--big": big,
        "c-cell--bold": bold,
        "c-cell--charge": charge,
        "c-cell--supplement": supplement,
        [classNameProps]: classNameProps
    });

    return <div className={className}>{children}</div>;
};

export default Cell;
