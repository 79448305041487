/* eslint-disable no-unused-vars */
import { Money } from "models/Invoice/Money";
import { ArrayField, Field } from "sparkson";
import { Marker } from "../Marker";
import { Charge } from "./Charge";

@Marker
export class BenefitsCharges {
    constructor(
        @ArrayField("benefits", Charge) public benefits: Charge[],
        @Field("total_charge", true) public totalCharge: Money
    ) {}
}
