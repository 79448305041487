import { BenefitsCharges } from "models/Invoice/BenefitsCharges";

import Controller from "../Controller";

class Benefits extends Controller {
    public getAvailableBenefits = async (): Promise<BenefitsCharges> => {
        const data = await this.get("/available_benefits");

        return this.parse(BenefitsCharges, data);
    };

    public getBenefits = async (userId: string): Promise<BenefitsCharges> => {
        const data = await this.get(`/employees/${userId}/benefits`);

        return this.parse(BenefitsCharges, data);
    };

    public addBenefit = async (userId: string, body: any): Promise<BenefitsCharges> => {
        const data = await this.post(`/employees/${userId}/benefits`, body);

        return this.parse(BenefitsCharges, data);
    };

    public deleteBenefit = async (userId: string, benefitId: number): Promise<BenefitsCharges> => {
        const data = await this.delete(`/employees/${userId}/benefits/${benefitId}`);

        return this.parse(BenefitsCharges, data);
    };
}

const benefits = new Benefits("");

export default benefits;
