import React from "react";

import { useComponentVisible } from "helpers/hooks/useComponentVisible";
import { Charge } from "models/Invoice/Charge";

import Button from "components/commons/Button/Button";
import AppStore from "stores/AppStore";
import EmployeeDataStore from "stores/EmployeeManagementStore";
import "./BenefitsRow.scss";

export interface Props {
    index: number;
    family: string;
    charge: Charge;
}

const BenefitsRow: React.FC<Props> = ({ index, charge, family }) => {
    const {
        i18n: {
            employees: { removeBenefitPackage, cancel, remove },
            invoicePreview: { pln }
        }
    } = AppStore;

    const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);

    const handleCancel = () => {
        setIsComponentVisible(false);
    };

    const handleRemove = async () => {
        await EmployeeDataStore.deleteBenefit(charge.id);

        setIsComponentVisible(false);
    };

    const renderBenefitRemoveModal = () => (
        <div className="c-benefits-row__remove-modal" ref={ref}>
            <div className="c-benefits-row__remove-modal-header">
                <h4>{removeBenefitPackage}</h4>
            </div>
            <div className="c-benefits-row__remove-modal-buttons">
                <Button className="c-benefits-row__remove-modal-button" text={cancel} cancel onClick={handleCancel} />
                <Button danger className="c-benefits-row__remove-modal-button" text={remove} onClick={handleRemove} />
            </div>
        </div>
    );

    return (
        <tr className="c-benefits-row">
            <td className="c-benefits-row__td u-bold">{!index && family}</td>
            <td className="c-benefits-row__td">
                {charge.packageName} ({charge.charge.toString()} {pln})
            </td>
            <td className="c-benefits-row__td c-benefits-row__td-remove">
                <span
                    className="c-benefits-row__remove"
                    onClick={() => {
                        setIsComponentVisible(true);
                    }}
                >
                    x
                </span>
                {isComponentVisible && renderBenefitRemoveModal()}
            </td>
        </tr>
    );
};
export default BenefitsRow;
