import { observer } from "mobx-react-lite";
import React from "react";
import { NavLink } from "react-router-dom";

import "./Menu.scss";

import { MenuItem } from "models/Components/MenuItem";
import AppStore from "stores/AppStore";

const Menu: React.FC = observer(() => {
    const {
        i18n: {
            menu: { yourInvoice, employees, invoices }
        },
        isAdmin,
        isManager
    } = AppStore;

    if (!isAdmin) {
        return null;
    }

    const items: MenuItem[] = [
        {
            pathname: "/invoice",
            text: yourInvoice
        }
    ];

    if (isManager) {
        items.push({
            pathname: "/invoices",
            text: invoices
        });
    }

    if (isAdmin) {
        items.push({
            pathname: "/employee",
            text: employees
        });
    }

    const renderItem = (item: MenuItem) => (
        <NavLink key={item.text} className="c-menu__item" activeClassName="c-menu__item--active" to={item.pathname}>
            {item.text}
        </NavLink>
    );

    return <div className="c-menu">{items.map(renderItem)}</div>;
});

export default Menu;
