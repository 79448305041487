import ReactDOM from "react-dom";
import { HashRouter as Router } from "react-router-dom";

import Fader from "components/commons/Fader/Fader";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { getClientId } from "conf/GoogleAuth";
import App from "./App";

ReactDOM.render(
    <GoogleOAuthProvider
        clientId={getClientId()}
    >
        <Router>
            <App />
            <Fader />
        </Router>
    </GoogleOAuthProvider>,
    document.getElementById("root")
);
