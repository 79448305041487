import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { observer } from "mobx-react-lite";

import Button from "components/commons/Button/Button";
import ButtonInline from "components/commons/ButtonInline/ButtonInline";
import Info from "components/commons/Info/Info";
import AppStore from "stores/AppStore";
import InvoiceStore from "stores/InvoiceStore";

import "./InvoiceState.scss";

const InvoicePreview = () => {
    const {
        invoice: {
            invoiceData: { status }
        }
    } = InvoiceStore;

    const {
        i18n: {
            invoicePreview: { weAreProcessingYourInvoice, weWillSendYouSlackMessage, invoiceIssued },
            invoiceStatuses: { downloadInvoice },
            commons: { youCanEnableAutomaticInvoiceIssuing, changeSettings }
        },
        setIsSettingsModalVisible,
        generateInvoicesAutomatically
    } = AppStore;

    const handleDownloadInvoice = () => {
        InvoiceStore.downloadInvoiceForActiveMonth();
    };

    const renderTexts = () => {
        let header = "";
        let subHeader = "";

        switch (status) {
            case "processing":
                header = weAreProcessingYourInvoice;
                subHeader = weWillSendYouSlackMessage;
                break;
            default:
                header = invoiceIssued;
                break;
        }

        return (
            <div className="c-invoice-state__texts-wrapper">
                <div className="c-invoice-state__icon">
                    <FontAwesomeIcon icon={faCheck} />
                </div>
                <div className="c-invoice-state__texts">
                    <h3 className="c-invoice-state__header">{header}</h3>
                    <p className="c-invoice-state__sub-header">{subHeader}</p>
                </div>
            </div>
        );
    };

    const renderInfo = () => {
        const infoText = (
            <>
                {youCanEnableAutomaticInvoiceIssuing}
                <ButtonInline
                    text={changeSettings}
                    onClick={() => {
                        setIsSettingsModalVisible(true);
                    }}
                />
            </>
        );

        return (
            <div className="c-invoice-state__info">
                <Info gray text={infoText} />
            </div>
        );
    };

    return (
        <div className="c-invoice-state">
            <div className="c-invoice-state__state">
                {renderTexts()}

                {status !== "processing" && <Button text={downloadInvoice} className="c-invoice-state__download-button" onClick={handleDownloadInvoice} />}
            </div>

            {!generateInvoicesAutomatically && renderInfo()}
        </div>
    );
};

export default observer(InvoicePreview);
