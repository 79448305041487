import classNames from "classnames";
import React from "react";

import { Props } from "./FieldInterface";

import "./Field.scss";

const Field: React.FC<Props> = ({ value, label, disabled, status, alignToLeft, className: classNameProps, additionalValue }) => {
    const getSign = () => {
        if (status === "positive") {
            return "+ ";
        }

        return "";
    };

    const className = classNames("c-field", {
        "c-field--align-to-left": alignToLeft,
        "c-field--disabled": disabled,
        [`c-field${status}`]: status,
        [classNameProps]: classNameProps
    });

    return (
        <div className={className}>
            {label && <div className="c-field__label">{label}</div>}
            <div className="c-field__value">
                {getSign()}
                {value}
            </div>
            {additionalValue && <div className="c-field__additional-value">{additionalValue}</div>}
        </div>
    );
};

export default Field;
