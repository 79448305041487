import { action, makeObservable, observable } from "mobx";

import employee from "api/controllers/Employee";
import { Invoice } from "models/Invoice/Invoice";
import InvoiceStore from "stores/InvoiceStore";

class Store {
    @observable public selectedEmployee?: string;
    @observable public activeMonth?: Date;
    @observable public invoice?: Invoice;

    constructor() {
        makeObservable(this);
    }

    @action public resetStore = async () => {
        this.selectedEmployee = undefined;
        this.activeMonth = undefined;
        this.invoice = undefined;
        InvoiceStore.setInvoice(undefined);
    };

    @action public resetInvoice = async () => {
        this.invoice = undefined;
    };

    @action public getInvoiceForActiveMonth = async () => {
        const invoice = await employee.getEmployeeInvoice(this.selectedEmployee, this.activeMonth);

        InvoiceStore.setInvoice(invoice);

        this.invoice = invoice;
    };

    @action public setSelectedEmployee = async (selectedEmployee: string) => {
        this.selectedEmployee = selectedEmployee;
    };

    @action public setActiveMonth = async (activeMonth: Date) => {
        this.activeMonth = activeMonth;
    };
}

const EmployeeInvoiceStore = new Store();

export default EmployeeInvoiceStore;
