import { observer } from "mobx-react-lite";
import React from "react";

import "./InvoiceListTable.scss";

import AppStore from "stores/AppStore";
import InvoiceListStore from "stores/InvoiceListStore";
import InvoiceListTableHeader from "./components/InvoiceListTableHeader/InvoiceListTableHeader";
import InvoiceListTableRows from "./components/InvoiceListTableRows/InvoiceListTableRows";

const InvoiceListTable = () => {
    const { invoiceStatusesFiltered } = InvoiceListStore;
    const {
        i18n: {
            employees: { nothingToShow }
        }
    } = AppStore;

    if (!invoiceStatusesFiltered.length) {
        return <h4 className="c-invoice-list-table__no-data">{nothingToShow}</h4>;
    }

    return (
        <table className="c-invoice-list-table">
            <InvoiceListTableHeader />
            <InvoiceListTableRows />
        </table>
    );
};

export default observer(InvoiceListTable);
