import AppStore from "stores/AppStore";
import InvoiceStore from "stores/InvoiceStore";

import Field from "components/commons/Field/Field";
import Row from "components/commons/Row/Row";
import "./Dates.scss";

const Dates = () => {
    const {
        invoice: {
            invoiceData: {
                dates: { issue, sales, due }
            }
        }
    } = InvoiceStore;

    const {
        i18n: {
            invoicePreview: { salesDate, paymentDueDate, issueDate }
        }
    } = AppStore;

    return (
        <div className="c-dates">
            <Row>
                <Field className="c-dates__field" value={issue} label={issueDate} disabled alignToLeft />
                <Field className="c-dates__field" label={salesDate} value={sales} disabled alignToLeft />
                <Field className="c-dates__field" label={paymentDueDate} value={due} disabled alignToLeft />
            </Row>
        </div>
    );
};

export default Dates;
