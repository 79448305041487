import classNames from "classnames";
import React, { useState } from "react";

import { Props } from "./SwitchInterface";

import "./Switch.scss";

const Switch: React.FC<Props> = ({ onClick, value, label, checked, description }: Props) => {
    const [isChecked, setIsChecked] = useState(checked);

    const handleClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        setIsChecked(!isChecked);
        onClick(event, { value, isChecked: !isChecked });
    };

    const switchClassName = classNames({
        "c-switch": true,
        "c-switch--checked": isChecked
    });

    return (
        <div className={switchClassName} onClick={handleClick}>
            <div className="c-switch__wrapper-circle">
                <div className="c-switch__circle" />
            </div>

            <div className="c-switch__texts">
                <div className="c-switch__label">{label}</div>
                {description && <div className="c-switch__description">{description}</div>}
            </div>
        </div>
    );
};

export default Switch;
