import { observer } from "mobx-react-lite";
import React from "react";

import "./ExtraChargesAndSupplements.scss";

import Cell from "components/commons/Cell/Cell";
import Divider from "components/commons/Divider/Divider";
import Field from "components/commons/Field/Field";
import Row from "components/commons/Row/Row";
import { InvoiceModificationDetails } from "models/Invoice/InvoiceModificationDetails";
import AppStore from "stores/AppStore";
import InvoiceStore from "stores/InvoiceStore";

const ExtraChargesAndSupplements = () => {
    const {
        invoice: {
            invoiceData: {
                invoiceModifications: { invoiceModifications, totalAmount }
            }
        }
    } = InvoiceStore;

    const {
        i18n: {
            invoiceDetails: { extraChargesAndSupplements, type: typeTranslate, amountNet, description: descriptionTranslate, totalExtras },
            employees: { charge, supplement }
        }
    } = AppStore;

    if (!invoiceModifications.length) {
        return null;
    }

    const getModificationTypeText = (modificationType) => {
        switch (modificationType) {
            case "charge":
                return charge;
            case "supplement":
                return supplement;
            default:
                return modificationType;
        }
    };

    const getDescription = (details: InvoiceModificationDetails) => {
        const { description } = details;
        return description;
    };

    const renderExtras = () =>
        invoiceModifications.map((invoiceModification) => {
            const { modificationType, id, details } = invoiceModification;
            const { amount } = details;

            return (
                <Row key={id}>
                    <Cell big bold alignToLeft className="c-extra-charges-and-supplements__type" supplement={modificationType !== "charge"} charge={modificationType === "charge"}>
                        {getModificationTypeText(modificationType)}
                    </Cell>
                    <Cell big alignToLeft className="c-extra-charges-and-supplements__description">
                        {getDescription(details)}
                    </Cell>
                    <Cell big>
                        <Field value={amount.toString()} disabled />
                    </Cell>
                </Row>
            );
        });

    return (
        <div className="c-extra-charges-and-supplements">
            <h4 className="c-extra-charges-and-supplements__h4">{extraChargesAndSupplements}</h4>

            <Row>
                <Cell alignToLeft>{typeTranslate}</Cell>
                <Cell alignToLeft>{descriptionTranslate}</Cell>
                <Cell>{amountNet}</Cell>
            </Row>

            <Divider />

            {renderExtras()}

            <Divider />

            <Row>
                <Cell />
                <Cell />
                <Cell>
                    <Field label={totalExtras} status={totalAmount.value < 0 ? "negative" : "positive"} value={`${totalAmount.toString()}`} />
                </Cell>
            </Row>
        </div>
    );
};

export default observer(ExtraChargesAndSupplements);
