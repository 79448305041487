import { observer } from "mobx-react-lite";
import React from "react";

import AppStore from "stores/AppStore";

import "./EmployeesTableHeader.scss";

const EmployeesTableHeader = () => {
    const {
        i18n: {
            employees: { name, id, employmentStart }
        }
    } = AppStore;

    return (
        <thead className="c-employees-table-header">
            <tr className="c-employees-table-header__tr">
                <th className="c-employees-table-header__th c-employees-table-header__name u-align-left">{name}</th>
                <th className="c-employees-table-header__th u-align-left">{id}</th>
                <th className="c-employees-table-header__th u-align-left">{employmentStart}</th>
            </tr>
        </thead>
    );
};

export default observer(EmployeesTableHeader);
