import { Field, Form, Formik, FormikProps } from "formik";
import { observer } from "mobx-react-lite";
import React from "react";

import "./AddModificationForm.scss";

import Button from "components/commons/Button/Button";
import ButtonsGroupInput from "components/commons/Formik/ButtonsGroupInput/ButtonsGroupInput";
import NumberInput from "components/commons/Formik/NumberInput/NumberInput";
import TextInput from "components/commons/Formik/TextInput/TextInput";
import Page from "components/commons/Page/Page";
import Row from "components/commons/Row/Row";
import { notEmpty } from "helpers/validators";
import { InvoiceModification } from "models/Invoice/InvoiceModification";
import AppStore from "stores/AppStore";
import EmployeeDataStore from "stores/EmployeeManagementStore";
import { Props } from "./AddModificationFormInterface";

const ChargeSupplementForm: React.FC<FormikProps<{ modificationType: string; amount: string; description: string }>> = (props) => {
    const { submitForm, handleReset, dirty, isValid } = props;

    const {
        i18n: {
            invoiceDetails: { amountNet, description, charge },
            employees: { cancel, pleaseProvideAmount, pleaseProvideDescription, pleaseSelectType, save, supplement }
        }
    } = AppStore;

    const isSubmitDisabled = !(dirty && isValid);

    return (
        <Form>
            <Row>
                <Page className="c-add-modification-form__page-first">
                    <Field
                        name="modificationType"
                        buttons={[
                            {
                                text: charge,
                                value: "charge"
                            },
                            {
                                text: supplement,
                                value: "supplement"
                            }
                        ]}
                        validate={notEmpty(pleaseSelectType)}
                        buttonClassName="c-add-modification-form__button"
                        component={ButtonsGroupInput}
                    />
                </Page>
                <Page className="c-add-modification-form__page-second">
                    <Field name="details.amount" type="number" validate={notEmpty(pleaseProvideAmount)} label={amountNet} component={NumberInput} />
                </Page>
            </Row>
            <Row>
                <Field name="details.description" label={description} validate={notEmpty(pleaseProvideDescription)} component={TextInput} />
            </Row>
            <Row>
                <Page className="c-add-modification-form__page-first">
                    <Button text={cancel} cancel onClick={handleReset} />
                </Page>
                <Page className="c-add-modification-form__page-second">
                    <Button disabled={isSubmitDisabled} text={save} onClick={submitForm} />
                </Page>
            </Row>
        </Form>
    );
};

const AddChargeSupplementForm: React.FC<Props> = ({ onCancel, onSubmit }) => {
    const {
        i18n: {
            employees: { addModification }
        }
    } = AppStore;

    const mapValues = (values: InvoiceModification) => ({
        details: {
            amount: Math.round(Number(values.details.amount) * 100),
            description: values.details.description
        },
        modification_type: values.modificationType
    });

    const handleSubmit = async (values) => {
        await EmployeeDataStore.addInvoiceModification(mapValues(values));

        onSubmit();
    };

    const handleReset = () => {
        onCancel();
    };

    return (
        <div className="c-add-modification-form">
            <h4 className="c-add-modification-form__header">{addModification}</h4>
            <Formik
                initialValues={{
                    amount: "",
                    description: "",
                    modificationType: ""
                }}
                onReset={handleReset}
                onSubmit={handleSubmit}
                component={ChargeSupplementForm}
            />
        </div>
    );
};

export default observer(AddChargeSupplementForm);
