import React, { ChangeEvent } from "react";

import { Props } from "./TextAreaInputInterface";

import "./TextAreaInput.scss";

const TextAreaInput: React.FC<Props> = ({ label, description, value, onChange }) => {
    const handleChange = (event: ChangeEvent<HTMLTextAreaElement>) => onChange(event.target.value);

    return (
        <div className="c-text-area-input">
            <div className="c-text-area-input__label">{label}</div>
            {description && <div className="c-text-area-input__description">{description}</div>}
            <textarea className="c-text-area-input__input" value={value} onChange={handleChange} />
        </div>
    );
};

export default TextAreaInput;
