import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ErrorMessage, FieldProps } from "formik";
import React, { useEffect, useRef, useState } from "react";

import { Props } from "./SelectInputInterface";

import "./SelectInput.scss";

const SelectInput: React.FC<FieldProps & Props> = (props) => {
    const {
        field,
        form: { setFieldValue },
        placeholder,
        options,
        disabled
    } = props;
    const { value, name } = field;

    const ref = useRef(null);
    const inputRef = useRef(null);
    const [isOpen, setIsOpen] = useState(false);
    const [visibleText, setVisibleText] = useState("");
    const [searchString, setSearchString] = useState("");

    const handleClick = () => {
        if (!isOpen) {
            inputRef.current.focus();
        }
    };

    const handleFocus = () => {
        setIsOpen(true);
    };

    const handleClickOption = (optionValue, text) => {
        setFieldValue(name, optionValue);
        inputRef.current.value = "";
        setVisibleText(text);
        setIsOpen(false);
    };

    const handleInputChange = (e) => {
        setSearchString(e.target.value);
    };

    const handleClickOutside = (event: MouseEvent) => {
        if (ref.current && !ref.current.contains(event.target)) {
            setIsOpen(false);
        }
    };

    const handleKeyDown = (event: KeyboardEvent) => {
        if (event.key === "Escape") {
            setIsOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener("click", handleClickOutside, true);
        document.addEventListener("keydown", handleKeyDown, true);
        return () => {
            document.removeEventListener("keydown", handleKeyDown, true);
            document.removeEventListener("click", handleClickOutside, true);
        };
    }, []);

    const renderOptions = () => {
        const filteredOptions = options.filter(({ text }) => text.toLowerCase().includes(searchString.toLowerCase()));

        return filteredOptions.map(({ value: optionValue, text }) => (
            <div
                className="c-select-input__option"
                key={text}
                onClick={() => {
                    handleClickOption(optionValue, text);
                }}
            >
                {text}
            </div>
        ));
    };

    return (
        <div
            className={`
        c-select-input
        ${disabled && "c-select-input--disabled"}
      `}
            ref={ref}
            onClick={handleClick}
        >
            <div className="c-select-input__wrapper">
                <input onChange={handleInputChange} className="c-select-input__input" ref={inputRef} autoComplete="off" type="text" disabled={disabled} placeholder={value ? visibleText : placeholder} onFocus={handleFocus} />
                <div className="c-select-input__input-icon">
                    <FontAwesomeIcon icon={isOpen ? faChevronUp : faChevronDown} />
                </div>
                <div className="c-select-input__error">
                    <ErrorMessage name={name} />
                </div>
            </div>

            {isOpen && (
                <div className="c-select-input__options-wrapper">
                    <div className="c-select-input__options">{renderOptions()}</div>
                </div>
            )}
        </div>
    );
};

export default SelectInput;
