import React from "react";

import Page from "components/commons/Page/Page";
import BenefitsManagement from "./components/BenefitsManagement/BenefitsManagement";
import CompanyDataManagement from "./components/CompanyDataManagement/CompanyDataManagement";
import ModificationsManagement from "./components/ModificationsManagement/ModificationsManagement";

import "./EmployeeManagement.scss";

const EmployeeManagement = () => (
    <div className="c-employee-management">
        <Page size="third" backgroundColor="gray" className="c-employee-management__page">
            <CompanyDataManagement />
        </Page>
        <Page size="third" backgroundColor="gray" className="c-employee-management__page">
            <BenefitsManagement />
        </Page>
        <Page size="third" backgroundColor="gray">
            <ModificationsManagement />
        </Page>
    </div>
);

export default EmployeeManagement;
